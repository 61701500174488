import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import ConfirmModal from "../modalContents/ConfirmModal";
import DefaultModal from "./DefaultModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteBlog } from "../states/features/blogs/blogsSlice";
import Vacancy from "../modalContents/Vacancy";
import { CgSpinner } from "react-icons/cg";
import { RiErrorWarningLine } from "react-icons/ri";

const BlogCard = ({ blog }) => {
  // URL
  const URL = process.env.REACT_APP_BASE_API_URL;

  // local state
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  // redux states
  const { isDeleting } = useSelector((state) => state.team);

  // location
  const location = useLocation();

  // handle update blog
  const handleUpdateBlog = (e) => {
    console.log(e);
  };

  // dispatch
  const dispatch = useDispatch();

  // handleCancel function
  const handleCancel = () => {
    setOpenModal((prev) => !prev);
  };

  // handleOk function
  const handleOk = (id) => {
    dispatch(deleteBlog(id));
  };

  // icon component
  const icon = <RiErrorWarningLine size={20} className="text-red-400" />;

  // cancel button component
  const canceButton = (
    <div
      className="border-gray-400 border-2 px-3 rounded text-gray-500 py-[1px] cursor-pointer text-[15px]"
      onClick={handleCancel}
    >
      Cancel
    </div>
  );

  // ok button component
  const okButton = (
    <div
      className="border-red-200 border-2 rounded py-[1px] px-3 overflow-hidden  text-red-500 cursor-pointer text-[15px]"
      onClick={handleOk}
    >
      {isDeleting ? (
        <div className="flex gap-1 items-center">
          <CgSpinner className="animate-spin" />
          <span>Deleting</span>
        </div>
      ) : (
        "Yes"
      )}
    </div>
  );

  return (
    <>
      <div
        className="col-12 col-md-6 col-lg-4 c-card col-12 col-md-6 s-blog-overview__card"
        onClick={location.pathname !== "/dashboard" ? null : handleUpdateBlog}
      >
        <Link
          className="c-card__link c-card__link--cover"
          onClick={() => window.scrollTo(0, 0)}
          to={`${
            location.pathname === "/dashboard" ? "#" : `/blog/${blog?.id}`
          }`}
        >
          <div className="c-image c-image--cover">
            <img
              className="attachment-c-card__background-image size-c-card__background-image wp-post-image c-image__img c-card__image "
              alt={`${blog?.title} illustration`}
              src={`${URL}/${blog?.image}`}
              sizes="(min-width: 412px) 480px, 375px, 480px"
              loading="lazy"
            />
          </div>
          <div className="c-card__inner c-card__inner--text-white relative">
            <header className="c-header c-card__header">
              <h3 className="c-header__heading ">{blog?.title}</h3>
            </header>
            {location.pathname === "/dashboard" && (
              <div className="px-3 py-2 bg-white w-fit rounded absolute top-[30px] right-[20px]">
                <button className="flex items-center space-x-3">
                  <FaTrash
                    size={14}
                    className="text-red-800"
                    onClick={() => setOpenModal(true)}
                  />
                  <FaEdit
                    size={16}
                    className="text-gray-500"
                    onClick={() => setOpenModal2(true)}
                  />
                </button>
              </div>
            )}
          </div>
        </Link>
      </div>

      {openModal && (
        <ConfirmModal
          text="Are you sure you want to delete this item?"
          icon={icon}
          cancelButton={canceButton}
          okButton={okButton}
        />
      )}
      <DefaultModal
        modalTitle="Update Job Vacancy"
        open={openModal2}
        width={450}
        setOpen={setOpenModal2}
        modalContent={<Vacancy setOpen={setOpenModal2} />}
      />
    </>
  );
};

export default BlogCard;
