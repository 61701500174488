// imports
import AboutContainer from "../containers/AboutContainer";
import AnalyticsContainer from "../containers/AnalyticsContainer";
import ContactContainer from "../containers/ContactContainer";
import SchedulesContainer from "../containers/SchedulesContainer";
import DataContainers from "../containers/DataContainers";
import HomeContainer from "../containers/HomeContainer";
import ResearchContainer from "../containers/ResearchContainer";
import BrcBoDContainer from "../containers/BrcBoDContainer";
import BrcTeamContainer from "../containers/BrcTeamContainer";
import BlogsContainer from "../containers/BlogsContainer";
import BlogContainer from "../containers/BlogContainer";
import VacanciesContainer from "../containers/VacanciesContainer";
import PrivacyPolicyContainer from "../containers/PrivacyPolicyContainer";
import DashboardContainer from "../containers/dashboard/DashboardContainer";
import TeamOfFellowsContainer from "../containers/TeamOfFellowsContainer";
import LoginContainer from "../containers/LoginContainer";
import NotFoundContainer from "../containers/NotFoundContainer";
import ForgotPWContainer from "../containers/ForgotPWContainer";
import ResetPWContainer from "../containers/ResetPWContainer";
import PublicationCategoriesContainer from "../containers/PublicationCategoriesContainer";
import CategoryPublicationsContainer from "../containers/CategoryPublicationsContainer";
import PublicationSummaryContainer from "../containers/PublicationSummaryContainer";

// unprotected pages defined
const HomePage = () => <HomeContainer />;
const BoDPage = () => <BrcBoDContainer />;
const BrcTeamPage = () => <BrcTeamContainer />;
const TeamOfFellowsPage = () => <TeamOfFellowsContainer />;
const ContactPage = () => <ContactContainer />;
const SchedulesPage = () => <SchedulesContainer />;
const AboutPage = () => <AboutContainer />;
const DataPage = () => <DataContainers />;
const ResearchPage = () => <ResearchContainer />;
const AnalyticsPage = () => <AnalyticsContainer />;
const PublicationCategoriesPage = () => <PublicationCategoriesContainer />;
const CategoryPublicationsPage = () => <CategoryPublicationsContainer />;
const PublicationSummaryPage = () => <PublicationSummaryContainer />
const BlogsPage = () => <BlogsContainer />;
const BlogPage = () => <BlogContainer />;
const VacanciesPage = () => <VacanciesContainer />;
const PrivacyPolicyPage = () => <PrivacyPolicyContainer />;
const LoginPage = () => <LoginContainer />;
const ForgotPWPage = () => <ForgotPWContainer />;
const ResetPWPage = () => <ResetPWContainer />;
const NotFoundPage = () => <NotFoundContainer />;

// protected pages
const DashboardPage = () => <DashboardContainer />;

// export
export {
  HomePage,
  BoDPage,
  BrcTeamPage,
  TeamOfFellowsPage,
  ContactPage,
  SchedulesPage,
  AboutPage,
  DataPage,
  ResearchPage,
  AnalyticsPage,
  PublicationCategoriesPage,
  CategoryPublicationsPage,
  PublicationSummaryPage,
  BlogsPage,
  BlogPage,
  VacanciesPage,
  PrivacyPolicyPage,
  DashboardPage,
  LoginPage,
  ForgotPWPage,
  ResetPWPage,
  NotFoundPage,
};
