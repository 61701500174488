import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import { useForm } from "react-hook-form";

import "react-quill/dist/quill.snow.css";
import PrimaryBtn from "../../../../components/PrimaryBtn";
import {
  addBlog,
  resetState,
} from "../../../../states/features/blogs/blogsSlice";

const BlogEditor = ({ content, setContent, type, setIndex }) => {
  // React hook form
  const { register, handleSubmit, formState } = useForm({
    mode: "all",
  });
  const { errors } = formState;

  // local states
  const [value, setValue] = useState(content);
  const editorRef = useRef(null);

  // dispatch
  const dispatch = useDispatch();

  // redux
  const { isCreating, isCreated, isUpdating, isUpdated } = useSelector(
    (state) => state.blogs,
  );

  const handleOnChange = (newValue) => {
    setValue(newValue);
    setContent(newValue);
  };

  const handleOnFocus = () => {
    editorRef.current.focus();
  };

  const onSubmit = ({ title, tag, author, excerpt, image }) => {
    const data = {
      title,
      tag,
      author,
      excerpt,
      content,
      image: image[0],
    };
    dispatch(addBlog(data));
  };

  // useEffect
  useEffect(() => {
    setValue(content);
  }, [content]);

  useEffect(() => {
    if (isCreated || isUpdated) {
      setIndex(0);
    }
    dispatch(resetState());
  }, [dispatch, isCreated, isUpdated, setIndex]);

  return (
    <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="flex flex-col md:flex-row justify-between space-x-0 md:space-x-3">
          <div className="w-full md:w-2/5 flex flex-col">
            <label className="text-[14px]">Blog Title</label>
            <input
              className="text-input w-full"
              type="text"
              name="title"
              {...register("title", {
                required: "Blog Title is required",
              })}
            />
            {errors.title && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.title.message}
              </span>
            )}
          </div>
          <div className="w-full md:w-1/5 flex flex-col">
            <label className="text-[14px]">Blog Tags</label>
            <input
              className="text-input w-full"
              type="text"
              name="tag"
              {...register("tag", {
                required: "Blog Tag is required",
              })}
            />
            {errors.tag && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.tag.message}
              </span>
            )}
          </div>
          <div className="w-full md:w-1/5 flex flex-col">
            <label className="text-[14px]">Author</label>
            <input
              className="text-input w-full"
              type="text"
              name="author"
              {...register("author", {
                required: "Blog Author is required",
              })}
            />
            {errors.author && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.author.message}
              </span>
            )}
          </div>
          <div className="w-full md:w-1/5 flex flex-col">
            <label className="text-[14px] mt-1">Blog Cover Image</label>
            {/* {type !== "new" && (
              <div className="mb-3">
                {publications?.data && (
                  <img
                    src={`${URL}/${publications?.data?.image}`}
                    alt={publications?.data?.fullname}
                    className="w-20 h-20 rounded-full object-cover"
                    loading="lazy"
                  />
                )}
              </div>
            )} */}
            <input
              className="mb-3"
              type="file"
              name="image"
              hidden={type !== "new"}
              accept=".png, .jpg, .jpeg"
              {...register("image", {
                ...(type === "new" && { required: "Image is required" }),
              })}
            />
            {errors.image && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.image.message}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between space-x-0 md:space-x-6">
          <div className="w-full">
            <label className="text-[14px]">Excerpt</label>
            <textarea
              className="text-input w-full"
              type="text"
              name="excerpt"
              {...register("excerpt", {
                required: "Excerpt is required",
              })}
            />
            {errors.excerpt && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.excerpt.message}
              </span>
            )}
          </div>
        </div>
      </div>
      <div onClick={handleOnFocus}>
        <label className="text-[14px]">Blog Content</label>
        <ReactQuill
          ref={editorRef}
          value={value}
          onChange={handleOnChange}
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, false] }],
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              ["image", "link", "formula", "clean"],
              [{ align: [] }],
            ],
          }}
          placeholder="Blog Content..."
        />
        <div className="mt-4">
          <PrimaryBtn
            type="submit"
            moreStyles="bg-primary ml-auto text-[16px]"
            title={type === "new" ? "Add Blog" : "Update Blog"}
            isLoading={type === "new" ? isCreating : isUpdating}
            loadingTitle="Adding a blog"
          />
        </div>
      </div>
    </form>
  );
};

export default BlogEditor;
