import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import settingsServices from "./settingsServices";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    settings: [],
    isError: false,
    isLoading: false,
    isUpdating: false,
    message: "",
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isUpdating = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.settings = payload;
      })
      .addCase(getSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
        state.settings = [];
      })
      .addCase(updateSettings.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateSettings.fulfilled, (state, { payload }) => {
        state.isUpdating = false;
        state.isUpdated = true;
        state.message = payload;
      })
      .addCase(updateSettings.rejected, (state, { payload }) => {
        state.isUpdating = false;
        state.isError = true;
        state.message = payload;
        state.settings = [];
      });
  },
});

// Get all company settings
export const getSettings = createAsyncThunk(
  "get/settings",
  async (thunkAPI) => {
    try {
      return await settingsServices.getSettings();
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Update company settings
export const updateSettings = createAsyncThunk(
  "update/settings",
  async (data, thunkAPI) => {
    try {
      return await settingsServices.updateSettings(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const { reset } = settingsSlice.actions;

export default settingsSlice.reducer;
