import { Link, NavLink, useLocation } from "react-router-dom";
import { darkLogo } from "../assets";
import { quickLinks } from "../constants";
import { FaLinkedinIn, FaTwitter } from "react-icons/fa";

const Footer = () => {
  // get location
  const location = useLocation();
  const isLocationSpecial =
    location?.pathname === "/dashboard" ||
    location?.pathname === "/login" ||
    location?.pathname === "/forgot-password" ||
    location?.pathname.startsWith("/reset-password/");
  return (
    <>
      {isLocationSpecial ? null : (
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-3 left">
                <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                  <img
                    className="alignnone size-medium wp-image-1743"
                    alt="BRC dark logo"
                    width="134"
                    height="50"
                    src={darkLogo}
                    loading="lazy"
                  />
                </Link>

                <ul className="c-social-accounts">
                  <li className="c-social-accounts__item">
                    <a
                      className="c-social-accounts__link c-social-accounts__link--twitter"
                      href="https://twitter.com/BusinessResear8"
                      target="_blank"
                      data-wpel-link="external"
                      rel="external noopener noreferrer"
                    >
                      <FaLinkedinIn className="hover:text-[#f68920]" />
                    </a>
                  </li>

                  <li className="c-social-accounts__item">
                    <a
                      className="c-social-accounts__link c-social-accounts__link--linkedin"
                      href="https://twitter.com/BusinessResear8"
                      target="_blank"
                      data-wpel-link="external"
                      rel="external noopener noreferrer"
                    >
                      <FaTwitter className="hover:text-[#f68920]" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-lg-9 right">
                <div className="row top footer--three-336">
                  <div className="col-12 col-md-3 footer__column-content">
                    <span className="card-title--small">Quick Links</span>
                    {quickLinks.map(({ index, link, name }) => (
                      <p key={index}>
                        <NavLink
                          to={link}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {name}{" "}
                        </NavLink>
                      </p>
                    ))}
                  </div>
                  <div className="col-12 col-md-3 footer__column-content">
                    <span className="card-title--small">Related Links</span>
                    <p>
                      <a
                        href="https://www.psf.org.rw/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        PSF Website
                      </a>
                    </p>
                  </div>

                  <div className="col-12 col-md-6 footer__column-content">
                    <span className="card-title--small">What we do</span>
                    <p>
                      <NavLink
                        to="data-collection"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Data
                      </NavLink>{" "}
                      |{" "}
                      <NavLink
                        to="business-research"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Business Research
                      </NavLink>{" "}
                      |{" "}
                      <NavLink
                        to="business-analytics"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Analytics
                      </NavLink>
                    </p>
                  </div>
                </div>

                <div className="row bottom">
                  <div className="col-12 text-center text-md-left">
                    <div className="wrapper">
                      <div className="footer__copyright">
                        <p>
                          © Business Research Centre {new Date().getFullYear()}
                        </p>

                        <span className="footer__pipe">|</span>
                      </div>
                      <div className="footer__extra-links">
                        <NavLink
                          className="footer__extra-links-link"
                          to="privacy-guidelines"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          Privacy Guidelines
                        </NavLink>
                        <span className="footer__pipe">|</span>
                      </div>
                      <div className="footer__webdesign-by">
                        <p>
                          <a
                            href="https://link.bizcotap.com/profile/5689001546315d3c5869e9"
                            target="_blank"
                            rel="noopener external noreferrer"
                          >
                            Designed by John N
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
