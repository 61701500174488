import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import AdminRoutes from "./outlets/AdminRoutes";
import VerifyAuth from "./helpers/VerifyAuth";
import { getNetworkStatus, notification } from "./utils";

import {
  AboutPage,
  AnalyticsPage,
  BlogPage,
  BlogsPage,
  ContactPage,
  DashboardPage,
  DataPage,
  HomePage,
  PrivacyPolicyPage,
  PublicationCategoriesPage,
  CategoryPublicationsPage,
  PublicationSummaryPage,
  BoDPage,
  BrcTeamPage,
  VacanciesPage,
  TeamOfFellowsPage,
  LoginPage,
  NotFoundPage,
  ForgotPWPage,
  ResetPWPage,
  ResearchPage,
  SchedulesPage,
} from "./pages";

const App = () => {
  const [isOnline, setIsOnline] = useState(getNetworkStatus());
  const [isReloaded, setIsReloaded] = useState(true);

  useEffect(() => {
    const handleNetworkChange = () => {
      setIsOnline(getNetworkStatus());
    };

    window.addEventListener("offline", handleNetworkChange);
    window.addEventListener("online", handleNetworkChange);

    return () => {
      window.removeEventListener("offline", handleNetworkChange);
      window.removeEventListener("online", handleNetworkChange);
    };
  }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    if (!isReloaded && !isOnline) {
      notification(
        "You are offline, some content won't be visible",
        "info",
        "bottomLeft",
      );
    }
    setIsReloaded(false);
  }, [isReloaded, isOnline]);

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route element={<AdminRoutes />}>
          <Route path="/dashboard" element={<DashboardPage />} />
        </Route>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/data-collection" element={<DataPage />} />
        <Route path="/business-research" element={<ResearchPage />} />
        <Route path="/business-analytics" element={<AnalyticsPage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/blog/:id" element={<BlogPage />} />
        <Route path="/brc-team" element={<BrcTeamPage />} />
        <Route path="/board-of-directors" element={<BoDPage />} />
        <Route path="/team-of-fellows" element={<TeamOfFellowsPage />} />
        <Route path="/vacancies" element={<VacanciesPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/schedules" element={<SchedulesPage />} />
        <Route path="/privacy-guidelines" element={<PrivacyPolicyPage />} />
        <Route path="/publications" element={<PublicationCategoriesPage />} />
        <Route
          path="/publications/:slug/:categoryId"
          element={<CategoryPublicationsPage />}
        />
        <Route
          path="/publications/:slug/:categoryId/summary/:publicationId"
          element={<PublicationSummaryPage />}
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPWPage />} />
        <Route path="/reset-password/:resetToken" element={<ResetPWPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
      <VerifyAuth />
    </Router>
  );
};

export default App;
