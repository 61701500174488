import { Modal } from "antd";

const DefaultModal = ({ modalTitle, open, setOpen, modalContent, width }) => {
  // handle cancel
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      title={modalTitle}
      width={width}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="mt-6 flex flex-col sm:flex-row space-x-4">
        {modalContent}
      </div>
    </Modal>
  );
};

export default DefaultModal;
