import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeamMembers } from "../states/features/team/teamSlice";
import Spinner from "../components/Spinner";

const BrcTeam = () => {
  // URL
  const URL = process.env.REACT_APP_BASE_API_URL;

  // dispatch
  const dispatch = useDispatch();

  // staff
  const { teamMembers, isFetchingAll } = useSelector((state) => state.team);

  // cache data until changed
  const data = useMemo(() => teamMembers?.data || [], [teamMembers?.data]);

  // useEffect
  useEffect(() => {
    dispatch(getTeamMembers());
  }, [dispatch]);

  return (
    <section id="section-number-2" className="section-number-2 team">
      <div className="container">
        <div className="row intro">
          <div className="col-12 intro">
            <h2>Meet our Staff</h2>
            <p className="text-justify">
              Welcome to the BRC team! Our skilled professionals are the driving
              force behind our success and innovation.
            </p>
          </div>
        </div>
        {data.length === 0 && isFetchingAll ? (
          <Spinner moreStyles="min-h-[30vh]" />
        ) : (
          <section className="max-w-[90%] md:max-w-[80%] mx-auto">
            {data &&
              data
                .filter((team) => team.category === "Office Staff")
                .map((team, index, filteredArray) => (
                  <React.Fragment key={index}>
                    <div
                      className={`flex flex-col md:flex-row items-center gap-10 ${
                        index % 2 === 0 ? "" : "md:flex-row-reverse"
                      } py-8`}
                    >
                      <div className="w-[200px] md:w-1/3">
                        <img
                          className="rounded-full w-[200px] md:w-[304px] h-[200px] md:h-[304px] object-cover shadow"
                          alt={team.fullname}
                          src={`${URL}/${team?.image}`}
                          loading="lazy"
                        />
                      </div>
                      <div className="w-full md:w-2/3">
                        <div className="text-center md:text-left">
                          <div className="xl:flex xl:items-start xl:flex-col">
                            <h3 className="text-2xl mb-1 xl:mr-4 font-semibold text-gray-800">
                              {team.fullname}
                            </h3>
                            <span className="text-gray-600">
                              {team.position}
                            </span>
                          </div>
                        </div>
                        <p className="mt-4 text-gray-700 text-justify">
                          {team.bio}
                        </p>
                      </div>
                    </div>
                    {index < filteredArray.length - 1 && (
                      <div className="w-full my-8">
                        <div className="h-px bg-gray-300"></div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
          </section>
        )}
      </div>
    </section>
  );
};

export default BrcTeam;
