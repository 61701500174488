import React from "react";

const RoundedBtn = ({ title, moreStyles, onClick }) => {
  return (
    <button
      className={`${moreStyles} px-10 font-semibold rounded`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default RoundedBtn;
