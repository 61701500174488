import { AiOutlineArrowUp } from "react-icons/ai";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ImSpinner2 } from "react-icons/im";
import {
  requestDocument,
  resetState,
} from "../states/features/userDownloadHistory/userDownloadHistorySlice";
import { notification } from "../utils";
import { getSinglePublication } from "../states/features/publications/publicationsSlice";
import { useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import truncateString from "../utils/truncateString";
import NoteTag from "../components/NoteTag";

const PublicationSummaryContainer = () => {
  // local state
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState(null);

  // params
  const { publicationId } = useParams();

  // redux
  const { singlePublication, isFetchingOne } = useSelector(
    (state) => state.publications
  );

  // react hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });

  // dispatch
  const dispatch = useDispatch();

  // redux states
  const { isRequesting, isRequested, isError, message } = useSelector(
    (state) => state.userDownloadHistory
  );

  // handle submission
  const onSubmit = ({ fullname, email }) => {
    dispatch(requestDocument({ fullname, email, link }));
  };

  // handle modal
  const handleModal = (title) => {
    setOpen((prev) => !prev);
    setLink(title);
  };

  useEffect(() => {
    if (isError) {
      notification(message, "error", "bottomLeft");
    }
    if (isRequested) {
      reset({
        fullname: "",
        email: "",
      });
      setOpen(false);
    }
    dispatch(resetState());
  }, [isRequested, isError, dispatch, link, reset, message]);

  // useEffect
  useEffect(() => {
    dispatch(getSinglePublication(publicationId));
  }, [dispatch, publicationId]);

  console.log(singlePublication);

  return (
    <>
      {isFetchingOne ? (
        <Spinner moreStyles="min-h-[100vh]" />
      ) : (
        <>
          {singlePublication.data && (
            <main>
              <section className="mx-auto mt-[130px] max-w-[600px]">
                <div>
                  <h3 className="text-center">
                    {singlePublication?.data?.title}
                  </h3>
                  <hr className="mt-3 border-[1px] border-primary rounded-[50%]" />
                </div>
                <div className="my-6">
                  <p className="content text-justify mx-4 sm:mx-0">
                    {truncateString(singlePublication?.data?.overview, 1000)}
                  </p>
                </div>
                <div className="mx-4 sm:mx-[100px] -mt-2 mb-3 ">
                  <button
                    className="flex items-center space-x-3 transition duration-800 rounded-md w-full justify-center py-1 hover:bg-gray-100"
                    onClick={() => handleModal(singlePublication?.data?.title)}>
                    <span className="text-primary">Request Publication Document</span>
                    <span>
                      <AiOutlineArrowUp
                        className="animate-bounce text-[#f48920]"
                        size={20}
                      />
                    </span>
                  </button>
                </div>
              </section>
            </main>
          )}
        </>
      )}

      <Modal
        centered
        open={open}
        width={400}
        footer={false}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}>
        <div className="download-container -mt-12">
          <Form className="download-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="pt-10">
              <NoteTag type="info" text="Enter details for document request." />
            </div>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Full name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your full name"
                {...register("fullname", {
                  required: true,
                })}
              />
              {errors.fullname?.type === "required" && (
                <span className="text-red-700 text-[13px]">
                  Full names required
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a valid email"
                {...register("email", {
                  required: true,
                  pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
                })}
              />
              {(errors.email?.type === "required" && (
                <span className="text-red-700 text-[13px]">
                  Email is required
                </span>
              )) ||
                (errors.email?.type === "pattern" && (
                  <span className="text-red-700 text-[13px]">
                    Email is not valid
                  </span>
                ))}
            </Form.Group>

            <button className="flex items-center space-x-3 transition duration-800 rounded-md bg-floor w-full justify-center py-2 hover:bg-gray-300 mt-4">
              {!isRequesting ? (
                "Request document"
              ) : (
                <span className="flex justify-center items-center text-gray-700">
                  Requesting document
                  <ImSpinner2 size={20} className="animate-spin ml-2" />
                </span>
              )}
            </button>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default PublicationSummaryContainer;
