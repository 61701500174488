import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PublicationsCategoriesCard from "../components/PublicationsCategoriesCard";
import Spinner from "../components/Spinner";
import { getCategories } from "../states/features/categories/categoriesSlice";
import { Empty } from "antd";

const Work = () => {
  // URL
  const URL = process.env.REACT_APP_BASE_API_URL;

  // dispatch
  const dispatch = useDispatch();

  // redux
  const { categories, isFetchingAll } = useSelector(
    (state) => state.categories,
  );

  // useEffect
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const recentPublicationCategories =
    categories?.data && categories?.data?.slice(0, 3);

  return (
    <section
      id="section-number-4"
      className="section-number-4 s-featured-related bg-my-work"
    >
      <div className="container s-featured-related__inner">
        <header className="c-header s-featured-related__header text-center">
          <h2 className="c-heading c-header__heading">Our Recent Works</h2>
        </header>

        <>
          {isFetchingAll ? (
            <Spinner />
          ) : (
            <>
              {recentPublicationCategories &&
              recentPublicationCategories.length === 0 ? (
                <div className="my-[10vh] flex items-center justify-center w-full">
                  <Empty description="No recent publications yet" />
                </div>
              ) : (
                <div className="row s-featured-related__posts md:-mt-20">
                  {recentPublicationCategories &&
                    recentPublicationCategories.map((category) => (
                      <PublicationsCategoriesCard
                        key={category?.id}
                        image={`${URL}/${category?.image}`}
                        name={category?.name}
                        description={category?.description}
                        slug={category?.slug}
                        categoryId={category?.id}
                      />
                    ))}
                </div>
              )}
            </>
          )}
        </>
      </div>
    </section>
  );
};

export default Work;
