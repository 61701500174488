import API from "../../../api/api";
import { teamMemberEndpoint } from "../../../constants";
import { notification } from "../../../utils";

// Get all Team members
const getTeamMembers = async () => {
  const response = await API.get(teamMemberEndpoint);
  return response.data;
};

// Get a Team member
const getTeamMember = async (id) => {
  const response = await API.get(`${teamMemberEndpoint}/${id}`);
  return response.data;
};

// Add a Team Member
const addTeamMember = async (data) => {
  const response = await API.post(teamMemberEndpoint, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// Update a team member
const updateTeamMember = async ({
  id,
  fullname,
  email,
  phone,
  position,
  category,
  bio,
  image,
}) => {
  const response = await API.put(`${teamMemberEndpoint}/${id}`, {
    fullname,
    email,
    phone,
    position,
    category,
    bio,
    image,
  });
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// Delete a team member
const deleteTeamMember = async (id) => {
  const response = await API.delete(`${teamMemberEndpoint}/${id}`);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

const teamServices = {
  getTeamMembers,
  getTeamMember,
  addTeamMember,
  updateTeamMember,
  deleteTeamMember,
};

export default teamServices;
