import API from "../../../api/api";
import { contactsEndpoint } from "../../../constants";
import { notification } from "../../../utils";

// Get all contacts
const getContacts = async () => {
  const response = await API.get(contactsEndpoint);
  return response.data;
};

// Create a contact message
const createContactMessage = async (data) => {
  const response = await API.post(contactsEndpoint, data);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

const contactServices = {
  getContacts,
  createContactMessage,
};

export default contactServices;
