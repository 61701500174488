import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import publicationsServices from "./publicationsServices";

const publicationsSlice = createSlice({
  name: "publications",
  initialState: {
    allPublications: [],
    publications: [],
    publication: [],
    singlePublication: [],
    isError: false,
    isSuccess: false,
    isFetchingAll: false,
    isFetchingOne: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    message: "",
  },
  reducers: {
    resetState: (state) => {
      state.isFetchingAll = false;
      state.isFetchingOne = false;
      state.isCreating = false;
      state.isUpdating = false;
      state.isDeleting = false;
      state.isSuccess = false;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDeleted = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPublications.pending, (state) => {
        state.isFetchingAll = true;
      })
      .addCase(getAllPublications.fulfilled, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isSuccess = true;
        state.allPublications = payload;
      })
      .addCase(getAllPublications.rejected, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isError = true;
        state.message = payload;
        state.allPublications = [];
      })
      .addCase(getPublications.pending, (state) => {
        state.isFetchingAll = true;
      })
      .addCase(getPublications.fulfilled, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isSuccess = true;
        state.publications = payload;
      })
      .addCase(getPublications.rejected, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isError = true;
        state.message = payload;
        state.publications = [];
      })
      .addCase(getPublication.pending, (state) => {
        state.isFetchingOne = true;
      })
      .addCase(getPublication.fulfilled, (state, { payload }) => {
        state.isFetchingOne = false;
        state.isSuccess = true;
        state.publication = payload;
      })
      .addCase(getPublication.rejected, (state, { payload }) => {
        state.isFetchingOne = false;
        state.isError = true;
        state.message = payload;
        state.publication = [];
      })
      .addCase(getSinglePublication.pending, (state) => {
        state.isFetchingOne = true;
      })
      .addCase(getSinglePublication.fulfilled, (state, { payload }) => {
        state.isFetchingOne = false;
        state.isSuccess = true;
        state.singlePublication = payload;
      })
      .addCase(getSinglePublication.rejected, (state, { payload }) => {
        state.isFetchingOne = false;
        state.isError = true;
        state.message = payload;
        state.singlePublication = [];
      })
      .addCase(addPublication.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(addPublication.fulfilled, (state, { payload }) => {
        state.isCreating = false;
        state.isSuccess = true;
        state.isCreated = true;
        state.message = payload;
      })
      .addCase(addPublication.rejected, (state, { payload }) => {
        state.isCreating = false;
        state.isError = true;
        state.message = payload;
        state.publications = [];
      })
      .addCase(updatePublication.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updatePublication.fulfilled, (state, { payload }) => {
        state.isUpdating = false;
        state.isSuccess = true;
        state.isUpdated = true;
        state.message = payload;
      })
      .addCase(updatePublication.rejected, (state, { payload }) => {
        state.isUpdating = false;
        state.isError = true;
        state.message = payload;
        state.publications = [];
      })
      .addCase(deletePublication.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(deletePublication.fulfilled, (state, { payload }) => {
        state.isDeleting = false;
        state.isSuccess = true;
        state.isDeleted = true;
        state.message = payload;
      })
      .addCase(deletePublication.rejected, (state, { payload }) => {
        state.isDeleting = false;
        state.isError = true;
        state.message = payload;
        state.publications = [];
      });
  },
});

// Get all publications
export const getAllPublications = createAsyncThunk(
  "get/allPublications",
  async (thunkAPI) => {
    try {
      return await publicationsServices.getAllPublications();
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Get all publication in a category
export const getPublications = createAsyncThunk(
  "get/publications",
  async (id, thunkAPI) => {
    try {
      return await publicationsServices.getPublications(id);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Get a publication
export const getPublication = createAsyncThunk(
  "get/publication",
  async (id, thunkAPI) => {
    try {
      return await publicationsServices.getPublication(id);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);
// Get a single publication
export const getSinglePublication = createAsyncThunk(
  "get/single/publication",
  async (id, thunkAPI) => {
    try {
      return await publicationsServices.getSinglePublication(id);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Add a publication
export const addPublication = createAsyncThunk(
  "add/publication",
  async (data, thunkAPI) => {
    try {
      return await publicationsServices.addPublication(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Update a publication
export const updatePublication = createAsyncThunk(
  "update/publication",
  async (data, thunkAPI) => {
    try {
      return await publicationsServices.updatePublication(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Delete a publication
export const deletePublication = createAsyncThunk(
  "delete/publication",
  async (id, thunkAPI) => {
    try {
      return await publicationsServices.deletePublication(id);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const { resetState } = publicationsSlice.actions;

export default publicationsSlice.reducer;
