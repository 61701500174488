import React from "react";
import { bigSurveys } from "../assets";

const OurData = () => {
  return (
    <main>
      <section id="section-number-3" className="section-number-3 s-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2 content">
              <div className="c-content s-content__content">
                <h1 style={{ textAlign: "center" }}>Our data services</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="section-number-4"
        className="section-number-4 s-content-image  "
      >
        <div className="container">
          <div className="row  h-reverse-order--row ">
            <div className="col-12 col-md-5  offset-md-1   s-content-image__image--aligned-center  s-content-image__image">
              <div className="c-image ">
                <img
                  className="attachment-s-content-image__image size-s-content-image__image wp-post-image c-image__img"
                  alt="survey illustration"
                  src={bigSurveys}
                  sizes="480px, 480px"
                />
              </div>
            </div>
            <div className="col-12 col-md-6   s-content-image__content  s-content-image__content--aligned-center ">
              <header className="c-header">
                <h3 className="c-heading c-header__heading c-heading--h2 ">
                  Data Collection Service
                </h3>
              </header>

              <div className="c-content s-content-image__content">
                <p className="text-justify">
                  As the research arm of Rwanda's Private Sector Federation
                  (PSF), the BRC is dedicated to supporting the business
                  community. We collect accurate information through surveys,
                  interviews, and focus groups, which forms the foundation for
                  in-depth analysis, innovative business models, and strategic
                  advisory services. By bridging research gaps in Rwanda's
                  private sector, BRC empowers stakeholders with crucial
                  insights, facilitating informed advocacy and decision-making
                  for sustainable economic growth and a thriving business
                  landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default OurData;
