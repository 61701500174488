import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import PropTypes from "prop-types";

const Wrapper = ({
  title,
  description,
  image,
  alt,
  buttonContent,
  moreStyles,
}) => {
  return (
    <section id="section-number-1" className="section-number-1 s-page-header">
      <div className="s-page-header__inner s-page-header__inner-- text-color-dark js-page-header">
        <div className="c-image s-page-header__background-image c-image--cover">
          <img
            className="attachment-s-page-header__header size-s-page-header__header wp-post-image c-image__img"
            alt={alt}
            src={image}
            loading="lazy"
          />
        </div>
        <div className="container">
          <div className="row ">
            <div className="col-12 col-md-6 ">
              <div className={`content ${moreStyles}`}>
                <header
                  className="c-header s-page-header__header "
                  data-aos="fade-in"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-out"
                  data-aos-delay="50"
                >
                  <h1 className="c-heading c-header__heading c-heading--h2 ">
                    {title}
                  </h1>
                </header>

                <div
                  className="c-content s-page-header__content "
                  data-aos="fade-in"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-out"
                  data-aos-delay="50"
                >
                  <p className="body-large">{description}</p>
                </div>

                {buttonContent && (
                  <button
                    className="s-page-header__buttons"
                    data-aos="fade-in"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out"
                    data-aos-delay="50"
                  >
                    <Link
                      className="c-button c-button--link-with-icon s-page-header__button-1"
                      to="about-us"
                    >
                      <span className="c-button__label">{buttonContent}</span>
                      <div className="c-image c-button__icon ">
                        <MdKeyboardArrowRight size={20} />
                      </div>
                    </Link>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Wrapper;

// Type Checking using PropTypes
Wrapper.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  buttonContent: PropTypes.string,
  quote: PropTypes.string,
  moreStyles: PropTypes.string,
};
