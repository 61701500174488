import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userDownloadHistoryServices from "./userDownloadHistoryServices";

const userDownloadHistorySlice = createSlice({
  name: "userDownloadHistory",
  initialState: {
    userDownloadHistory: [],
    isError: false,
    isSuccess: false,
    isFetchingAll: false,
    isRequesting: false,
    isRequested: false,
    message: "",
  },
  reducers: {
    resetState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetchingAll = false;
      state.isRequesting = false;
      state.isRequested = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDownloadHistory.pending, (state) => {
        state.isFetchingAll = true;
      })
      .addCase(getUserDownloadHistory.fulfilled, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isSuccess = true;
        state.userDownloadHistory = payload;
      })
      .addCase(getUserDownloadHistory.rejected, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isError = true;
        state.message = payload;
        state.userDownloadHistory = [];
      })

      .addCase(requestDocument.pending, (state) => {
        state.isRequesting = true;
      })
      .addCase(requestDocument.fulfilled, (state, { payload }) => {
        state.isRequesting = false;
        state.isSuccess = true;
        state.isRequested = true;
        state.message = payload;
      })
      .addCase(requestDocument.rejected, (state, { payload }) => {
        state.isRequesting = false;
        state.isError = true;
        state.message = payload;
        state.userDownloadHistory = [];
      });
  },
});

// Get all user download history
export const getUserDownloadHistory = createAsyncThunk(
  "get/userDownloadHistory",
  async (thunkAPI) => {
    try {
      return await userDownloadHistoryServices.getUserDownloadHistory();
    } catch (error) {
      console.log(error);
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Request Document
export const requestDocument = createAsyncThunk(
  "request/document",
  async (data, thunkAPI) => {
    try {
      return await userDownloadHistoryServices.requestDocument(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const { resetState } = userDownloadHistorySlice.actions;

export default userDownloadHistorySlice.reducer;
