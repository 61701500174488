import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contactServices from "./contactServices";

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    contacts: [],
    isError: false,
    isSuccess: false,
    isFetchingAll: false,
    isCreating: false,
    isCreated: false,
    message: "",
  },
  reducers: {
    resetState: (state) => {
      state.isFetchingAll = false;
      state.isCreating = false;
      state.isSuccess = false;
      state.isCreated = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContacts.pending, (state) => {
        state.isFetchingAll = true;
      })
      .addCase(getContacts.fulfilled, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isSuccess = true;
        state.contacts = payload;
      })
      .addCase(getContacts.rejected, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isError = true;
        state.message = payload;
        state.contacts = [];
      })
      .addCase(createContactMessage.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(createContactMessage.fulfilled, (state, { payload }) => {
        state.isCreating = false;
        state.isSuccess = true;
        state.isCreated = true;
        state.message = payload;
      })
      .addCase(createContactMessage.rejected, (state, { payload }) => {
        state.isCreating = false;
        state.isError = true;
        state.message = payload;
        state.contacts = [];
      });
  },
});

// Get contact messages
export const getContacts = createAsyncThunk(
  "get/contacts",
  async (thunkAPI) => {
    try {
      return await contactServices.getContacts();
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// create a contact message
export const createContactMessage = createAsyncThunk(
  "create/contact-message",
  async (data, thunkAPI) => {
    try {
      return await contactServices.createContactMessage(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const { resetState } = contactSlice.actions;

export default contactSlice.reducer;
