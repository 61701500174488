import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import truncateString from "../utils/truncateString";

const PublicationsCategoriesCard = ({
  image,
  name,
  description,
  slug,
  categoryId,
}) => {
  return (
    <div className="col-12 col-md-6 col-lg-4 s-publication-overview__card">
      <div className="bg-white border-[0.5] border-gray-50 rounded-lg shadow-primary shadow-sm overflow-hidden">
        <div className="c-image s-publication-overview__card-image w-full">
          <img
            className="attachment-s-publication-overview__card size-s-publication-overview__card wp-post-image c-image__img"
            alt="Leaders in teaching case study"
            src={image}
            sizes="430px, 430px"
          />
        </div>

        <header className="c-header s-publication-overview__card-header px-3">
          <h3 className="c-heading c-header__heading  s-publication-overview__card-heading">
            {name}
          </h3>
        </header>
        <div className="px-3 -mt-2 mb-3">
          <p className="mb-3 font-normal text-gray-600">
            {truncateString(description, 120)}
          </p>
        </div>

        <div className="px-3 -mt-4 mb-3">
          <button className="s-page-header__buttons w-full">
            <Link
              onClick={() => window.scrollTo(0, 0)}
              className="c-button c-button--link-with-icon s-page-header__button-1 flex items-center"
              to={`/publications/${slug}/${categoryId}`}
            >
              <span className="c-button__label">
                View all Category Publications
              </span>
              <div className="c-image c-button__icon ">
                <MdKeyboardArrowRight size={20} />
              </div>
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PublicationsCategoriesCard;
