import moment from "moment";
import PropTypes from "prop-types";

const DateFormater = ({ value }) => {
  return (
    <div>
      <span className="text-[13px]">{moment(value).format("MMM Do YYYY")}</span>
    </div>
  );
};

export default DateFormater;

DateFormater.prototype = {
  value: PropTypes.string.isRequired,
};
