import React, { useEffect } from "react";
import Wrapper from "../components/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import { getBlog } from "../states/features/blogs/blogsSlice";
import { useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import ContactFloatButton from "../components/ContactFloatButton";

const BlogContainer = () => {
  // URL
  const URL = process.env.REACT_APP_BASE_API_URL;

  // dispatch
  const dispatch = useDispatch();

  // useParams
  const { id } = useParams();

  // staff
  const { blog, isFetchingAll } = useSelector((state) => state.blogs);

  // useEffect
  useEffect(() => {
    dispatch(getBlog(id));
  }, [dispatch, id]);

  return (
    <>
      {blog?.data && (
        <>
          {isFetchingAll ? (
            <Spinner moreStyles="min-h-[100vh]" />
          ) : (
            <main>
              <Wrapper
                title={blog?.data?.title}
                description={blog?.data?.excerpt}
                image={`${URL}/${blog?.data?.image}`}
                alt={`${blog?.data?.title} - Illustration`}
              />
              <div className="container my-10">
                <div className="row news-item">
                  <div className="col-12 col-md-8 offset-md-2">
                    <div />
                    <p
                      className="content text-justify"
                      dangerouslySetInnerHTML={{ __html: blog?.data?.content }}
                    />
                  </div>
                </div>
              </div>
            </main>
          )}
        </>
      )}
      <ContactFloatButton />
    </>
  );
};

export default BlogContainer;
