import { useEffect, useState } from "react";
import {
  AiOutlineLogout,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
} from "react-icons/ai";
import { HiUserCircle } from "react-icons/hi";
import { Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { logout, resetState } from "../../../states/features/auth/authSlice";

// Navbutton component
const NavButton = ({ title, customFunc, icon }) => (
  <Tooltip placement="bottom" title={title} color={"#0e76bc"}>
    <button
      type="button"
      onClick={() => customFunc()}
      className="relative text-xl rounded-full p-3 hover:bg-gray-200"
    >
      {icon}
    </button>
  </Tooltip>
);

const Navbar = ({
  currentColor,
  activeMenu,
  setActiveMenu,
  screenSize,
  setScreenSize,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  // redux state
  const { user } = useSelector((state) => state.auth);

  // logout
  const onLogout = () => {
    dispatch(logout());
    dispatch(resetState());
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [setScreenSize]);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [setActiveMenu, screenSize]);

  // useEffect
  useEffect(() => {
    if (openModal) {
      document.body.classList.add("block-scroll");
    } else {
      document.body.classList.remove("block-scroll");
    }
  }, [openModal, setOpenModal]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div className="bg-white flex justify-between py-2 px-6 sm:px-4 relative border-b-[1px] border-tertiary">
      <NavButton
        customFunc={handleActiveMenu}
        color={currentColor}
        icon={
          activeMenu ? (
            <AiOutlineMenuFold className="text-dark" />
          ) : (
            <AiOutlineMenuUnfold className="text-dark" />
          )
        }
      />
      <div className="flex items-center space-x-2 relative">
        <HiUserCircle size={40} className="text-gray-400" />
        <div className="bg-gray-100 py-1 px-3 rounded-md">
          <span className="text-dark text-[14px]">Logged In as,</span>{" "}
          <span className="text-dark font-bold ml-1 text-[14px]">
            {user && user?.name}
          </span>
        </div>
        <div>
          <NavButton
            title="Logout"
            customFunc={onLogout}
            color={currentColor}
            icon={<AiOutlineLogout className="text-red-400" />}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
