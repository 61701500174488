import API from "../../../api/api";
import { categoriesEndpoint } from "../../../constants";
import { notification } from "../../../utils";

// Get all categories
const getCategories = async () => {
  const response = await API.get(categoriesEndpoint);
  return response.data;
};

// Get a category
const getCategory = async (id) => {
  const response = await API.get(`${categoriesEndpoint}/${id}`);
  return response.data;
};

// Add a category
const addCategory = async (data) => {
  const response = await API.post(categoriesEndpoint, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// Update a category
const updateCategory = async ({
  id,
  name,
  description,
  image

}) => {
  console.log(id);
  const response = await API.put(`${categoriesEndpoint}/${id}`, {
    name,
    description,
    image
  },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    },
  );
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// Delete a category
const deleteCategory = async (id) => {
  const response = await API.delete(`${categoriesEndpoint}/${id}`);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

const categoriesServices = {
  getCategories,
  getCategory,
  addCategory,
  updateCategory,
  deleteCategory,
};

export default categoriesServices;
