import API from "../../../api/api";
import {
  forgotpwEndpoint,
  resetpwEndpoint,
  loginEndpoint,
  lsUser,
} from "../../../constants";
import { notification } from "../../../utils";

// login Service
const login = async (userData) => {
  const response = await API.post(loginEndpoint, userData);
  if (response?.data?.ok) {
    localStorage.setItem(lsUser, JSON.stringify(response.data));
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data;
};

// forgot password Service
const forgotpw = async (data) => {
  const response = await API.post(forgotpwEndpoint, data);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// reset password Service
const resetpw = async (data) => {
  const response = await API.put(`${resetpwEndpoint}/${data.resetToken}`, data);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// logout Service
const logout = () => {
  localStorage.removeItem(lsUser);
};

const authService = {
  login,
  forgotpw,
  resetpw,
  logout,
};

export default authService;
