import { useState } from "react";
import { useDispatch } from "react-redux";
import { RiLoader4Fill } from "react-icons/ri";

import PrimaryTextArea from "../../../components/PrimaryTextArea";
import PrimaryInput from "../../../components/PrimaryInput";
import PrimaryBtn from "../../../components/PrimaryBtn";
import { darkLogo } from "../../../assets";

import { updateSettings } from "../../../states/features/settings/settingsSlice";
import NoteTag from "../../../components/NoteTag copy";

const CompanySettings = ({ settings, isLoading, isUpdating }) => {
  // dispatch
  const dispatch = useDispatch();

  // local states
  const [formData, setFormData] = useState({
    companyName: settings?.data[0]?.companyName,
    companyEmail: settings?.data[0]?.companyEmail,
    companyPhone: settings?.data[0]?.companyPhone,
    companyMission: settings?.data[0]?.companyMission,
    companyVision: settings?.data[0]?.companyVision,
    country: settings?.data[0]?.country,
    province: settings?.data[0]?.province,
    district: settings?.data[0]?.district,
    sector: settings?.data[0]?.sector,
    cell: settings?.data[0]?.cell,
    streetNumber: settings?.data[0]?.streetNumber,
  });

  // on change function
  const handleFormInputs = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // handle update
  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch(updateSettings(formData));
  };

  return (
    <div className="px-10 min-h-[80vh] flex items-center justify-center">
      <div className="h-fit">
        {isLoading ? (
          <RiLoader4Fill className="animate-spin text-gray-700" size={25} />
        ) : (
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-3/5 order-2 md:order-1 ">
              <h3 className="font-bold mt-8 mb-4 text-[18px] ">
                Company Information Settings
              </h3>
              <NoteTag
                type="info"
                text="Please note that some of the information on
          this page has been pre-filled. If you need to
          make any changes or updates, please feel free to do so."
              />
              {settings?.data && (
                <form className="mt-4 space-y-6">
                  <PrimaryInput
                    label="Company Name"
                    value={formData?.companyName}
                    name="companyName"
                    onChange={handleFormInputs}
                  />
                  <div>
                    <div className="flex flex-col md:flex-row items-center justify-between space-x-0 md:space-x-5">
                      <PrimaryInput
                        label="Company Email"
                        value={formData?.companyEmail}
                        name="companyEmail"
                        onChange={handleFormInputs}
                      />
                      <PrimaryInput
                        label="Company Phone Number"
                        value={formData?.companyPhone}
                        name="companyPhone"
                        onChange={handleFormInputs}
                      />
                    </div>
                  </div>
                  <PrimaryTextArea
                    label="Company Mission"
                    value={formData?.companyMission}
                    name="companyMission"
                    onChange={handleFormInputs}
                  />
                  <PrimaryTextArea
                    label="Company Vision"
                    value={formData?.companyVision}
                    name="companyVision"
                    onChange={handleFormInputs}
                  />
                  <div>
                    <label className="form-label">Address</label>
                    <div className="flex flex-col md:flex-row items-center justify-between space-x-0 md:space-x-5">
                      <input
                        className="account-form-input"
                        type="text"
                        value={formData?.country}
                        name="country"
                        onChange={handleFormInputs}
                      />
                      <input
                        className="account-form-input"
                        type="text"
                        value={formData?.province}
                        name="province"
                        onChange={handleFormInputs}
                      />
                    </div>
                    <div className="flex flex-col md:flex-row items-center justify-between space-x-0 md:space-x-5">
                      <input
                        className="account-form-input"
                        type="text"
                        value={formData?.district}
                        name="district"
                        onChange={handleFormInputs}
                      />
                      <input
                        className="account-form-input"
                        type="text"
                        value={formData?.sector}
                        name="sector"
                        onChange={handleFormInputs}
                      />
                    </div>
                    <div className="flex flex-col md:flex-row items-center justify-between space-x-0 md:space-x-5">
                      <input
                        className="account-form-input"
                        type="text"
                        value={formData?.cell}
                        name="cell"
                        onChange={handleFormInputs}
                      />
                      <input
                        className="account-form-input"
                        type="text"
                        value={formData.streetNumber}
                        name="streetNumber"
                        onChange={handleFormInputs}
                      />
                    </div>
                  </div>
                  <div>
                    <PrimaryBtn
                      type="submit"
                      moreStyles="bg-primary ml-auto"
                      title={"Update Settings"}
                      isLoading={isUpdating}
                      loadingTitle={"Updating"}
                      onClick={handleUpdate}
                      disabled={isUpdating}
                    />
                  </div>
                </form>
              )}
            </div>
            <div className="w-full ml-0 md:ml-14 md:w-2/5 order-1 md:order-2 pt-[20px] md:pt-0">
              <div className="flex justify-center mt-12">
                <img
                  src={darkLogo}
                  className="w-[150px]"
                  alt="profile"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanySettings;
