import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCategory,
  getCategory,
  resetState,
} from "../states/features/categories/categoriesSlice";
import PrimaryBtn from "../components/PrimaryBtn";
import Spinner from "../components/Spinner";

const UpdateCategory = ({ setOpen, id }) => {
  const { register, handleSubmit, formState, setValue } = useForm({
    mode: "all",
  });
  const { errors } = formState;

  const { category, isUpdating, isUpdated, isFetchingOne } = useSelector(
    (state) => state.categories
  );

  const dispatch = useDispatch();

  const onSubmitForm = ({ name, description, image }) => {
    const imageData = image[0];
    console.log({ name, description, imageData });
    dispatch(updateCategory({ id, name, description, image: imageData }));
  };

  useEffect(() => {
    dispatch(getCategory(id));
    if (isUpdated) {
      setOpen(false);
      dispatch(resetState());
    }
  }, [dispatch, isUpdated, setOpen, id]);

  useEffect(() => {
    if (category?.data) {
      setValue("name", category.data.name);
      setValue("description", category.data.description);
    }
  }, [category, setValue]);

  return (
    <>
      {isFetchingOne ? (
        <Spinner moreStyles="min-h-[10vh]"/>
      ) : (
        <form className="w-full" onSubmit={handleSubmit(onSubmitForm)}>
          {/* Names Section */}
          <div className="flex flex-col">
            <label className="text-[14px]">Publication Category Name</label>
            <input
              className="text-input w-full"
              type="text"
              name="name"
              {...register("name", { required: true })}
            />
            <span className="text-red-600 text-[13px] mb-2 -mt-2">
              {errors.name && "Publication category name is required"}
            </span>
          </div>
          <div className="flex flex-col">
            <label className="text-[14px]">
              Publication Category Description
            </label>
            <textarea
              className="text-input w-full"
              rows={8}
              type="text"
              name="description"
              {...register("description", { required: true })}
            />
            <span className="text-red-600 text-[13px] mb-2 -mt-2">
              {errors.description && "Publication description is required"}
            </span>
          </div>

          <div className="flex flex-col">
            <label className="text-[14px]">Cover Image</label>
            <input
              className="mb-3"
              type="file"
              name="image"
              accept="image/*"
              {...register("image", { required: "Cover Image is required" })}
            />
            {errors.image && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.image.message}
              </span>
            )}
          </div>

          <div className="mt-2">
            <PrimaryBtn
              type="submit"
              moreStyles="bg-primary ml-auto"
              title={"Update Publication Category"}
              isLoading={isUpdating}
              loadingTitle="Updating Publication Category"
            />
          </div>
        </form>
      )}
    </>
  );
};

export default UpdateCategory;
