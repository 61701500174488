import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MdDashboard, MdSwitchAccount } from "react-icons/md";

import MobileSideBar from "./MobileSideBar";

import { darkLogo } from "../assets";

const Navbar = () => {
  // local state
  const [open, setOpen] = useState(false);

  // check user
  const { user } = useSelector((state) => state.auth);
  // translations
  const { t, i18n } = useTranslation();

  // get location
  const location = useLocation();
  const isLocationSpecial =
    location?.pathname === "/dashboard" ||
    location?.pathname === "/login" ||
    location?.pathname === "/forgot-password" ||
    location?.pathname.startsWith("/reset-password/");

  // current language
  const currentLanguage = localStorage.getItem("i18nextLng");
  const [language, setLanguage] = useState(currentLanguage);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  return (
    <>
      {isLocationSpecial ? null : (
        <header className="site-header">
          <div className="container site-header__container">
            <div className="site-header__branding">
              <Link
                className="site-header__branding-link"
                onClick={() => window.scrollTo(0, 0)}
                to="/"
              >
                <div className="c-image site-header__branding-logo site-header__branding-logo--dark">
                  <img
                    src={darkLogo}
                    className="alignnone size-medium wp-image-1743"
                    alt="BRC dark logo"
                    width="134"
                    height="50"
                    loading="lazy"
                  />
                </div>
              </Link>
            </div>

            <nav className="nav">
              <div className="nav__toggler">
                <button
                  className="nav__toggler-btn hamburger hamburger--spring"
                  type="button"
                  aria-label="Toggle navigation"
                  onClick={() => setOpen((prev) => !prev)}
                >
                  <span className="hamburger-box nav__toggler-icon">
                    <span className="hamburger-inner nav__toggler-icon-inner"></span>
                  </span>
                  <span className="nav__toggler-text">Menu </span>
                </button>
              </div>

              <div className="nav__container">
                <ul
                  id="menu-primary-menu-en"
                  className="nav__menu nav__menu--primary"
                >
                  <li className="nav__menu-item nav__menu-item--top-level">
                    <NavLink
                      className="nav-link nav__menu-link nav__menu-link--top-level"
                      to="about-us"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {t("navbar.about")}
                    </NavLink>
                  </li>
                  <li className="nav__menu-item nav__menu-item--top-level nav__menu-item--has-children">
                    <NavLink className="nav-link nav__menu-link nav__menu-link--top-level">
                      {t("navbar.whatWeDo.title")}
                    </NavLink>
                    <ul className="nav__sub-menu sub-menu">
                      <li className="nav__sub-menu-item">
                        <NavLink
                          className="nav-link nav__sub-menu-link"
                          to="data-collection"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {t("navbar.whatWeDo.subItem1")}
                        </NavLink>
                      </li>
                      <li className="nav__sub-menu-item">
                        <NavLink
                          className="nav-link nav__sub-menu-link"
                          to="business-research"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {t("navbar.whatWeDo.subItem2")}
                        </NavLink>
                      </li>
                      <li className="nav__sub-menu-item">
                        <NavLink
                          className="nav-link nav__sub-menu-link"
                          to="business-analytics"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {t("navbar.whatWeDo.subItem3")}
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li className="nav__menu-item nav__menu-item--top-level">
                    <NavLink
                      className="nav-link nav__menu-link nav__menu-link--top-level"
                      to="publications"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {t("navbar.publications")}
                    </NavLink>
                  </li>

                  <li className="nav__menu-item nav__menu-item--top-level">
                    <NavLink
                      className="nav-link nav__menu-link nav__menu-link--top-level"
                      to="blogs"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {t("navbar.blog")}
                    </NavLink>
                  </li>
                  <li className="nav__menu-item nav__menu-item--top-level nav__menu-item--has-children">
                    <NavLink className="nav-link nav__menu-link nav__menu-link--top-level">
                      {t("navbar.ourTeam.title")}
                    </NavLink>
                    <ul className="nav__sub-menu sub-menu">
                      <li className="nav__sub-menu-item">
                        <NavLink
                          className="nav-link nav__sub-menu-link"
                          to="board-of-directors"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {t("navbar.ourTeam.subItem0")}
                        </NavLink>
                      </li>
                      <li className="nav__sub-menu-item">
                        <NavLink
                          className="nav-link nav__sub-menu-link"
                          to="brc-team"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {t("navbar.ourTeam.subItem1")}
                        </NavLink>
                      </li>
                      <li className="nav__sub-menu-item">
                        <NavLink
                          className="nav-link nav__sub-menu-link"
                          to="team-of-fellows"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {t("navbar.ourTeam.subItem2")}
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li className="nav__menu-item nav__menu-item--top-level nav__menu-item--has-children">
                    <NavLink className="nav-link nav__menu-link nav__menu-link--top-level">
                      {language}
                    </NavLink>
                    <ul className="nav__sub-menu sub-menu">
                      <li className="nav__sub-menu-item">
                        <NavLink
                          className="nav-link nav__sub-menu-link"
                          onClick={() => changeLanguage("en")}
                        >
                          {t("navbar.languages.english")}
                        </NavLink>
                      </li>
                      <li className="nav__sub-menu-item">
                        <NavLink
                          className="nav-link nav__sub-menu-link"
                          onClick={() => changeLanguage("fr")}
                        >
                          {t("navbar.languages.french")}
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav__menu-item nav__menu-item--top-level">
                    {user?.role === "admin" ? (
                      <NavLink
                        className="nav-link nav__menu-link nav__menu-link--top-level"
                        to="dashboard"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <MdDashboard size="30" className="text-primary" />
                      </NavLink>
                    ) : (
                      <NavLink
                        className="nav-link nav__menu-link nav__menu-link--top-level"
                        to="login"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <MdSwitchAccount size="30" className="text-primary" />
                      </NavLink>
                    )}
                  </li>
                </ul>
                <ul
                  id="menu-buttons-menu-en"
                  className="nav__menu nav__menu--buttons c-buttons-menu"
                >
                  <li className="nav__menu-item nav__menu-item--top-level">
                    <NavLink
                      className="nav-link nav__menu-link nav__menu-link--top-level"
                      to="schedules"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {t("navbar.schedules")}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      )}
      <MobileSideBar
        open={open}
        setOpen={setOpen}
        changeLanguage={changeLanguage}
        t={t}
        role={user?.role}
        language={language}
      />
    </>
  );
};

export default Navbar;
