import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Wrapper from "../components/Wrapper";
import CategoryPublications from "../sections/CategoryPublications";
import { getPublications } from "../states/features/publications/publicationsSlice";
import { getCategory } from "../states/features/categories/categoriesSlice";
import WrapperSchema from "../components/WrapperSchema";
import ContactFloatButton from "../components/ContactFloatButton";

const CategoryPublicationsContainer = () => {
  // get category id
  const { categoryId } = useParams();

  // URL
  const URL = process.env.REACT_APP_BASE_API_URL;

  // dispatch
  const dispatch = useDispatch();

  // redux
  const { publications, isFetchingAll } = useSelector(
    (state) => state.publications,
  );
  const { category, isFetchingOne } = useSelector((state) => state.categories);

  // useEffect
  useEffect(() => {
    dispatch(getCategory(categoryId));
  }, [dispatch, categoryId]);

  // useEffect
  useEffect(() => {
    dispatch(getPublications(categoryId));
  }, [dispatch, categoryId]);

  return (
    <main>
      {isFetchingOne ? (
        <WrapperSchema />
      ) : (
        <>
          {category?.data && (
            <Wrapper
              title={category?.data?.name}
              description={category?.data?.description}
              image={`${URL}/${category?.data?.image}`}
              alt={`${category?.data?.name} - Wrapper Illustration`}
            />
          )}
        </>
      )}
      <CategoryPublications
        publications={publications?.data && publications?.data}
        isFetchingAll={isFetchingAll}
      />
      <ContactFloatButton />
    </main>
  );
};

export default CategoryPublicationsContainer;
