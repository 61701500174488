import Contact from "../sections/Contact";

const ContactContainer = () => {
  return (
    <main>
      <Contact />
    </main>
  );
};

export default ContactContainer;
