import ContactFloatButton from "../components/ContactFloatButton";
import BrcTeam from "../sections/BrcTeam";

const BrcTeamContainer = () => {
  return (
    <main>
      <BrcTeam />
      <ContactFloatButton />
    </main>
  );
};

export default BrcTeamContainer;
