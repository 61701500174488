import React from "react";
import BusinessResearch from "../sections/BusinessResearch";
import Wrapper from "../components/Wrapper";
import { businessResearchWrapper } from "../assets";
import ContactFloatButton from "../components/ContactFloatButton";

const ResearchContainer = () => {
  return (
    <main>
      <Wrapper
        title="Business Research"
        description="BRC's exceptional research capabilities drive success through data-driven insights and informed advocacy, establishing a deep understanding of the subject matter."
        image={businessResearchWrapper}
        alt="Business Research Page Wrapper"
      />
      <BusinessResearch />
      <ContactFloatButton />
    </main>
  );
};

export default ResearchContainer;
