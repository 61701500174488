import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addUnit, resetState } from "../states/features/units/unitsSlice";
import PrimaryBtn from "../components/PrimaryBtn";

const Unit = ({ setOpen }) => {
  const { register, handleSubmit, formState } = useForm({ mode: "all" });
  const { errors } = formState;

  const { isCreating, isCreated } = useSelector((state) => state.units);

  const dispatch = useDispatch();

  const onSubmitForm = (data) => {
    dispatch(addUnit(data));
  };

  useEffect(() => {
    if (isCreated) {
      setOpen(false);
      dispatch(resetState());
    }
  }, [dispatch, isCreated, setOpen]);

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmitForm)}>
      {/* Names Section */}

      <div className="flex flex-col">
        <label className="text-[14px]">Unit Name</label>
        <input
          className="text-input w-full"
          type="text"
          name="name"
          {...register("name", { required: true })}
        />
        <span className="text-red-600 text-[13px] mb-2 -mt-2">
          {errors.name && "Unit Name is required"}
        </span>
      </div>

      <div className="flex flex-col">
        <label className="text-[14px]">Unit Description</label>
        <textarea
          className="text-input w-full"
          type="text"
          name="description"
          {...register("description", { required: true })}
        />
        <span className="text-red-600 text-[13px] mb-2 -mt-2">
          {errors.description && "Unit Descrption is required"}
        </span>
      </div>

      <div className="mt-2">
        <PrimaryBtn
          type="submit"
          moreStyles="bg-primary ml-auto"
          title={"New Unit"}
          isLoading={isCreating}
          loadingTitle="Adding Unit"
        />
      </div>
    </form>
  );
};

export default Unit;
