import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addCategory,
  resetState,
} from "../states/features/categories/categoriesSlice";
import PrimaryBtn from "../components/PrimaryBtn";

const Category = ({ setOpen }) => {
  const { register, handleSubmit, formState } = useForm({ mode: "all" });
  const { errors } = formState;

  const { isCreating, isCreated } = useSelector((state) => state.categories);

  const dispatch = useDispatch();

  const onSubmitForm = ({ name, description, image }) => {
    const imageData = image[0];
    dispatch(addCategory({ name, description, image: imageData }));
  };

  useEffect(() => {
    if (isCreated) {
      setOpen(false);
      dispatch(resetState());
    }
  }, [dispatch, isCreated, setOpen]);

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmitForm)}>
      {/* Names Section */}

      <div className="flex flex-col">
        <label className="text-[14px]">Publication Category Name</label>
        <input
          className="text-input w-full"
          type="text"
          name="name"
          {...register("name", { required: true })}
        />
        <span className="text-red-600 text-[13px] mb-2 -mt-2">
          {errors.name && "Publication category name is required"}
        </span>
      </div>
      <div className="flex flex-col">
        <label className="text-[14px]">Publication Category Description</label>
        <input
          className="text-input w-full"
          type="text"
          name="description"
          {...register("description", { required: true })}
        />
        <span className="text-red-600 text-[13px] mb-2 -mt-2">
          {errors.description && "Publication description is required"}
        </span>
      </div>

      <div className="flex flex-col">
        <label className="text-[14px]">Cover Image</label>
        <input
          className="mb-3"
          type="file"
          name="image"
          accept="image/*"
          {...register("image", { required: "Cover Image is required" })}
        />
        {errors.image && (
          <span className="text-red-600 text-[13px] mb-2 -mt-2">
            {errors.image.message}
          </span>
        )}
      </div>

      <div className="mt-2">
        <PrimaryBtn
          type="submit"
          moreStyles="bg-primary ml-auto"
          title={"New Publication Category"}
          isLoading={isCreating}
          loadingTitle="Adding Publication Category"
        />
      </div>
    </form>
  );
};

export default Category;
