import React from "react";
import Blog from "../sections/Blog";
import Wrapper from "../components/Wrapper";
import { blogWrapper } from "../assets";
import ContactFloatButton from "../components/ContactFloatButton";

const BlogsContainer = () => {
  return (
    <main>
      <Wrapper
        title="Blogs"
        description="Blogs bridge research gaps in Rwanda's private sector by sharing knowledge, connecting professionals, and inspiring research-driven approaches, fostering collaboration and business advancement."
        image={blogWrapper}
        alt="Wrapper - Blogs Page"
      />
      <Blog />
      <ContactFloatButton />
    </main>
  );
};

export default BlogsContainer;
