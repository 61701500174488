import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import { Empty } from "antd";

import { getTeamMembers } from "../../../states/features/team/teamSlice";
import Spinner from "../../../components/Spinner";
import RoundedBtn from "../../../components/RoundedBtn";
import DefaultModal from "../../../components/DefaultModal";
import TeamMember from "../../../modalContents/TeamMember";
import DateFormater from "../../../helpers/DateFormater";
import Actions from "../actions/team/Actions";
import Table from "../../../components/Table";
import truncateString from "../../../utils/truncateString";

const Team = () => {
  // URL
  const URL = process.env.REACT_APP_BASE_API_URL;

  // local states
  const [open, setOpen] = useState(false);

  // dispatch
  const dispatch = useDispatch();

  // team
  const { teamMembers, isFetchingAll, isCreated, isUpdated, isDeleted } =
    useSelector((state) => state.team);

  // useEffect
  useEffect(() => {
    dispatch(getTeamMembers());
  }, [dispatch, isCreated, isUpdated, isDeleted]);

  const columns = useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "fullname",
        Cell: ({ cell }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={`${URL}/${cell.row.original.image}`}
              alt="Avatar"
              style={{
                width: "30px",
                height: "30px",
                marginRight: "10px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
            <span
              style={{
                fontFamily: "GT-Walsheim-Regular",
                fontSize: "14px",
                color: "#374151",
              }}
            >
              {cell.value}
            </span>
          </div>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ cell }) => (
          <span
            style={{
              fontFamily: "GT-Walsheim-Regular",
              fontSize: "15px",
              color: "#374151",
            }}
          >
            {cell.value}
          </span>
        ),
      },
      {
        Header: "Position",
        accessor: "position",
        Cell: ({ cell }) => (
          <span
            title={cell.value}
            style={{
              fontFamily: "GT-Walsheim-Regular",
              fontSize: "15px",
              color: "#374151",
            }}
          >
            {truncateString(cell.value, 30)}
          </span>
        ),
      },
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ cell }) => (
          <span
            style={{
              fontFamily: "GT-Walsheim-Regular",
              fontSize: "15px",
              color: "#374151",
            }}
          >
            {cell.value}
          </span>
        ),
      },
      {
        Header: "Created",
        accessor: "createdAt",
        Cell: DateFormater,
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: Actions,
      },
    ],
    [URL],
  );

  // Store data in the memo
  const data = useMemo(() => teamMembers?.data || [], [teamMembers]);

  return (
    <>
      <div className="px-8 pt-12 md:pt-0">
        <div className="flex items-center justify-between">
          <h3 className="font-bold text-md mt-2">All BRC Team Members</h3>
          <RoundedBtn
            title="Add Team Member"
            moreStyles="bg-primary text-white py-2 text-sm"
            onClick={() => setOpen(true)}
          />
        </div>
        <div className="min-h-[80vh]">
          {data.length === 0 && isFetchingAll ? (
            <Spinner />
          ) : (
            <>
              {data.length === 0 ? (
                <div className="min-h-[70vh] flex items-center justify-center">
                  <Empty />
                </div>
              ) : (
                <div className="mt-4">
                  <Table columns={columns} data={data} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <DefaultModal
        modalTitle="Add Team Member"
        open={open}
        width={450}
        setOpen={setOpen}
        modalContent={<TeamMember setOpen={setOpen} type="new" />}
      />
    </>
  );
};

export default Team;
