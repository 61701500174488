import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "../states/features/settings/settingsSlice";
import Spinner from "../components/Spinner";
import { vision } from "../assets";

const Vision = () => {
  // dispatch
  const dispatch = useDispatch();

  // redux
  const { settings, isLoading } = useSelector((state) => state.settings);

  // cache data until changed
  const data = useMemo(() => settings?.data || [], [settings?.data]);

  // useEffect
  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);
  return (
    <section
      id="section-number-4"
      className="section-number-4 s-content-image  "
    >
      <div className="container">
        <div className="row  h-reverse-order--row ">
          <div className="col-12 col-md-5  offset-md-1   s-content-image__image--aligned-center  s-content-image__image">
            <div className="c-image">
              <img
                className="attachment-s-content-image__image size-s-content-image__image wp-post-image c-image__img"
                alt="Illustration of a vision"
                src={vision}
                sizes="480px, 480px"
              />
            </div>
          </div>
          <div className="col-12 col-md-6   s-content-image__content  s-content-image__content--aligned-center ">
            <header className="c-header">
              <h3 className="c-heading c-header__heading c-heading--h2 ">
                Our Vision
              </h3>
            </header>
            {data.length === 0 && isLoading ? (
              <Spinner moreStyles="my-0" />
            ) : (
              <div className="c-content s-content-image__content">
                <>
                  {data[0] && (
                    <p className="text-justify">{data[0].companyVision}</p>
                  )}
                </>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vision;
