import React from "react";
import Wrapper from "../components/Wrapper";
import { analysisWrapper } from "../assets";
import BusinessAnalytics from "../sections/BusinessAnalytics";
import ContactFloatButton from '../components/ContactFloatButton';

const AnalyticsContainer = () => {
  return (
    <main>
      <Wrapper
        title="Business Analytics"
        description="BRC delivers actionable insights from complex data, driving your business success through advanced analytics. Trust us for informed decision-making."
        image={analysisWrapper}
        alt="Wrapper - Business Analytics"
      />
      <BusinessAnalytics />
      <ContactFloatButton />
    </main>
  );
};

export default AnalyticsContainer;
