import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import { Empty } from "antd";

import { getUnits } from "../../../states/features/units/unitsSlice";
import Spinner from "../../../components/Spinner";
import RoundedBtn from "../../../components/RoundedBtn";
import DefaultModal from "../../../components/DefaultModal";
import Unit from "../../../modalContents/Unit";
import DateFormater from "../../../helpers/DateFormater";
import Actions from "../actions/units/Actions";
import Table from "../../../components/Table";

const Units = () => {
  // local states
  const [open, setOpen] = useState(false);

  // dispatch
  const dispatch = useDispatch();

  // units - redux state
  const { units, isFetchingAll, isCreated, isUpdated, isDeleted } = useSelector(
    (state) => state.units,
  );

  // useEffect
  useEffect(() => {
    dispatch(getUnits());
  }, [dispatch, isCreated, isUpdated, isDeleted]);

  const columns = useMemo(
    () => [
      {
        Header: "Unit Name",
        accessor: "name",
        Cell: ({ cell }) => (
          <span
            style={{
              fontFamily: "GT-Walsheim-Regular",
              fontSize: "15px",
              color: "#374151",
            }}
          >
            {cell.value}
          </span>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ cell }) => (
          <span
            style={{
              fontFamily: "GT-Walsheim-Regular",
              fontSize: "15px",
              color: "#374151",
            }}
          >
            {cell.value}
          </span>
        ),
      },
      {
        Header: "Created On",
        accessor: "createdAt",
        Cell: DateFormater,
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: Actions,
      },
    ],
    [],
  );

  // Store data in the memo
  const data = useMemo(() => units?.data || [], [units]);

  return (
    <>
      <div className="px-8 pt-12 md:pt-0">
        <div className="flex items-center justify-between">
          <h3 className="font-bold text-md mt-2">All Units</h3>
          <RoundedBtn
            title="Add Unit"
            moreStyles="bg-primary text-white py-2 text-sm"
            onClick={() => setOpen(true)}
          />
        </div>
        <div className="min-h-[80vh]">
          {data.length === 0 && isFetchingAll ? (
            <Spinner />
          ) : (
            <>
              {data.length === 0 ? (
                <div className="min-h-[70vh] flex items-center justify-center">
                  <Empty />
                </div>
              ) : (
                <div className="mt-4">
                  <Table columns={columns} data={data} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <DefaultModal
        modalTitle="Add Unit"
        open={open}
        width={450}
        setOpen={setOpen}
        modalContent={<Unit setOpen={setOpen} type="new" />}
      />
    </>
  );
};

export default Units;
