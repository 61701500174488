import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import vacanciesServices from "./vacanciesServices";

const vacanciesSlice = createSlice({
  name: "vacancies",
  initialState: {
    vacancies: [],
    isError: false,
    isSuccess: false,
    isFetchingAll: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    message: "",
  },
  reducers: {
    resetState: (state) => {
      state.isFetchingAll = false;
      state.isCreating = false;
      state.isUpdating = false;
      state.isDeleting = false;
      state.isSuccess = false;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDeleted = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVacancies.pending, (state) => {
        state.isFetchingAll = true;
      })
      .addCase(getVacancies.fulfilled, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isSuccess = true;
        state.vacancies = payload;
      })
      .addCase(getVacancies.rejected, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isError = true;
        state.message = payload;
        state.vacancies = [];
      })
      .addCase(addVacancy.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(addVacancy.fulfilled, (state, { payload }) => {
        state.isCreating = false;
        state.isSuccess = true;
        state.isCreated = true;
        state.message = payload;
      })
      .addCase(addVacancy.rejected, (state, { payload }) => {
        state.isCreating = false;
        state.isError = true;
        state.message = payload;
        state.vacancies = [];
      })
      .addCase(updateVacancy.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateVacancy.fulfilled, (state, { payload }) => {
        state.isUpdating = false;
        state.isSuccess = true;
        state.isUpdated = true;
        state.message = payload;
      })
      .addCase(updateVacancy.rejected, (state, { payload }) => {
        state.isUpdating = false;
        state.isError = true;
        state.message = payload;
        state.vacancies = [];
      })
      .addCase(deleteVacancy.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(deleteVacancy.fulfilled, (state, { payload }) => {
        state.isDeleting = false;
        state.isSuccess = true;
        state.isDeleted = true;
        state.message = payload;
      })
      .addCase(deleteVacancy.rejected, (state, { payload }) => {
        state.isDeleting = false;
        state.isError = true;
        state.message = payload;
        state.vacancies = [];
      });
  },
});

// Get all vacancies
export const getVacancies = createAsyncThunk(
  "get/vacancies",
  async (thunkAPI) => {
    try {
      return await vacanciesServices.getVacancies();
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Add a vacancy
export const addVacancy = createAsyncThunk(
  "add/vacancy",
  async (data, thunkAPI) => {
    try {
      return await vacanciesServices.addVacancy(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Update a vacancy
export const updateVacancy = createAsyncThunk(
  "update/vacancy",
  async (data, thunkAPI) => {
    try {
      return await vacanciesServices.updateVacancy(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Delete a Vacancy
export const deleteVacancy = createAsyncThunk(
  "delete/vacancy",
  async (id, thunkAPI) => {
    try {
      return await vacanciesServices.deleteVacancy(id);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const { resetState } = vacanciesSlice.actions;

export default vacanciesSlice.reducer;
