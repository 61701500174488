import API from "../../../api/api";
import { userDownloadHistoryEndpoint } from "../../../constants";
import { notification } from "../../../utils";

// Get user download history
const getUserDownloadHistory = async () => {
  const response = await API.get(userDownloadHistoryEndpoint);
  return response.data;
};

// Request document
const requestDocument = async (data) => {
  const response = await API.post(userDownloadHistoryEndpoint, data);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

const userDownloadHistoryServices = {
  getUserDownloadHistory,
  requestDocument,
};

export default userDownloadHistoryServices;
