import { ImSpinner2 } from "react-icons/im";

const PrimaryBtn = ({
  title,
  loadingTitle,
  onClick,
  type,
  disabled,
  isLoading,
  moreStyles,
  icon,
}) => {
  return (
    <>
      <button
        onClick={onClick}
        type={type}
        disabled={disabled}
        className={`${moreStyles} outline-none px-3 rounded-md focus:ring-[1.5px] focus:ring-primary focus:ring-offset-1 h-9 bg-primary ${
          isLoading ? null : "hover:bg-secondary"
        } ${isLoading ? "disabled:cursor-not-allowed" : null} text-white px-3`}
      >
        {isLoading ? (
          <span className="flex justify-center items-center">
            {loadingTitle}
            <ImSpinner2 size={20} className="animate-spin ml-2" />
          </span>
        ) : (
          <div className="flex items-center justify-center">
            {/* <IoIosAdd size={20} className="-ml-1" /> */}
            {icon}
            <span>{title}</span>
          </div>
        )}
      </button>
    </>
  );
};

export default PrimaryBtn;
