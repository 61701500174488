import React from "react";
import { researchPic } from "../assets";

const BusinessResearch = () => {
  return (
    <main>
      <section id="section-number-3" className="section-number-3 s-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2 content">
              <div className="c-content s-content__content">
                <h1 style={{ textAlign: "center" }}>Our research services</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="section-number-4"
        className="section-number-4 s-content-image"
      >
        <div className="container">
          <div className="row ">
            <div className="col-12 col-md-5    s-content-image__image--aligned-center  s-content-image__image">
              <div className="c-image ">
                <img
                  className="attachment-s-content-image__image size-s-content-image__image wp-post-image c-image__img"
                  alt="research pic"
                  src={researchPic}
                  sizes="480px, 480px"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-12 col-md-6  offset-md-1  s-content-image__content  s-content-image__content--aligned-center ">
              <header className="c-header">
                <h3 className="c-heading c-header__heading c-heading--h2 ">
                  Business Research
                </h3>
              </header>

              <div className="c-content s-content-image__content">
                <p className="pb-3 text-justify">
                  <span style={{ fontWeight: "400" }}>
                    Our researchers utilize surveys, interviews, focus groups,
                    and trend analysis to uncover valuable insights that enable
                    informed decision-making. BRC generates reports, policy
                    briefs, and innovative business models to drive unparalleled
                    success.{" "}
                  </span>{" "}
                </p>
                <p className="text-justify">
                  <span>
                    Moreover, we prioritize ensuring that our research is not
                    only suitable but also delivered promptly and relevant to
                    our clients' needs.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BusinessResearch;
