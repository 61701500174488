import ContactFloatButton from "../components/ContactFloatButton";
import TeamOfFellows from "../sections/TeamOfFellows";

const TeamOfFellowsContainer = () => {
  return (
    <main>
      <TeamOfFellows />
      <ContactFloatButton />
    </main>
  );
};

export default TeamOfFellowsContainer;
