import API from "../../../api/api";
import { settingsEndpoint } from "../../../constants";
import { notification } from "../../../utils";

// Get all company settings
const getSettings = async () => {
  const response = await API.get(settingsEndpoint);
  return response.data;
};

// Update all company settings information
const updateSettings = async (data) => {
  const response = await API.put(settingsEndpoint, data);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

const settingsServices = {
  getSettings,
  updateSettings,
};

export default settingsServices;
