import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClients } from "../states/features/clients/clientsSlice";
import Spinner from "../components/Spinner";

const OurClients = () => {
  // URL
  const URL = process.env.REACT_APP_BASE_API_URL;

  // dispatch
  const dispatch = useDispatch();

  // redux
  const { clients, isFetchingAll } = useSelector((state) => state.clients);

  // useEffect
  useEffect(() => {
    dispatch(getClients());
  }, [dispatch]);

  return (
    <section id="section-number-9" className="section-number-9 logos">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-6 offset-xl-3 s-header-wrapper">
            <header className="c-header s-logos__header">
              <h2 className="c-heading c-header__heading c-heading--h1 ">
                Our Partners
              </h2>
            </header>
          </div>
          {isFetchingAll ? (
            <Spinner moreStyles="my-0" />
          ) : (
            <div className="col-12 s-logos-wrapper">
              <div className="row">
                {clients?.data &&
                  clients.data.map((client) => (
                    <div
                      key={client.id}
                      className="col-6 col-sm-4 col-lg-2 column"
                    >
                      <div className="c-image s-logos__logo  w-[150px]">
                        <img
                          className="attachment-s-logos__logo size-s-logos__logo wp-post-image c-image__img w-full h-[80px] object-contain"
                          alt={client.name}
                          title={client.name}
                          src={`${URL}/${client.image}`}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default OurClients;
