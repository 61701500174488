import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { darkLogo } from "../assets";
import { forgotpw, resetState } from "../states/features/auth/authSlice";
import { ImSpinner2 } from "react-icons/im";
import { notification } from "../utils";
import ContactFloatButton from "../components/ContactFloatButton";

const ForgotPWContainer = () => {
  // translations
  const { t } = useTranslation();

  // react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // dispatch and navigate
  const dispatch = useDispatch();

  // redux states
  const { isSuccess, isError, isLoading, message } = useSelector(
    (state) => state.auth,
  );

  // handle submission
  const onSubmit = (data) => {
    dispatch(forgotpw(data));
  };

  // useEffect
  useEffect(() => {
    if (isError) {
      notification(message, "error", "bottomLeft");
    }
    if (isSuccess) {
      reset({
        email: "",
      });
    }
    dispatch(resetState());
  }, [isSuccess, reset, dispatch, message, isError]);

  return (
    <div className="forgotpw-container">
      <Form className="forgotpw-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="logo-container">
          <Link to="/">
            <img src={darkLogo} alt="logo" loading="lazy" />
          </Link>
          <p>
            {t("forgotpwPage.titlePart1")}
            <br /> {t("forgotpwPage.titlePart2")}
          </p>
        </div>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>{t("forgotpwPage.emailLabel")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("forgotpwPage.emailPlaceholder")}
            {...register("email", {
              required: true,
              pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
            })}
          />
          {(errors.email?.type === "required" && (
            <span className="text-red-700 text-[15px]">Email is required</span>
          )) ||
            (errors.email?.type === "pattern" && (
              <span className="text-red-700 text-[15px]">
                Email is not valid
              </span>
            ))}
        </Form.Group>

        <Button variant="primary" type="submit" disabled={isLoading}>
          {!isLoading ? (
            t("forgotpwPage.buttonText")
          ) : (
            <span className="flex justify-center items-center">
              Sending you a reset link
              <ImSpinner2 size={20} className="animate-spin ml-2" />
            </span>
          )}
        </Button>
        <div className="text-center mt-3">
          <div>
            {" "}
            <span className="text-[16px]">
              {t("forgotpwPage.footerPart1")}
            </span>{" "}
            <Link to="/login">{t("forgotpwPage.footerPart2")}</Link>
          </div>
        </div>
      </Form>
      <ContactFloatButton />
    </div>
  );
};

export default ForgotPWContainer;
