import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import teamServices from "./teamServices";

const teamSlice = createSlice({
  name: "team",
  initialState: {
    teamMembers: [],
    teamMember: [],
    isError: false,
    isSuccess: false,
    isFetchingAll: false,
    isFetchingOne: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    message: "",
  },
  reducers: {
    resetState: (state) => {
      state.isFetchingAll = false;
      state.isFetchingOne = false;
      state.isCreating = false;
      state.isUpdating = false;
      state.isDeleting = false;
      state.isSuccess = false;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDeleted = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamMembers.pending, (state) => {
        state.isFetchingAll = true;
      })
      .addCase(getTeamMembers.fulfilled, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isSuccess = true;
        state.teamMembers = payload;
      })
      .addCase(getTeamMembers.rejected, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isError = true;
        state.message = payload;
        state.teamMembers = [];
      })
      .addCase(getTeamMember.pending, (state) => {
        state.isFetchingOne = true;
      })
      .addCase(getTeamMember.fulfilled, (state, { payload }) => {
        state.isFetchingOne = false;
        state.isSuccess = true;
        state.teamMember = payload;
      })
      .addCase(getTeamMember.rejected, (state, { payload }) => {
        state.isFetchingOne = false;
        state.isError = true;
        state.message = payload;
        state.teamMember = [];
      })
      .addCase(addTeamMember.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(addTeamMember.fulfilled, (state, { payload }) => {
        state.isCreating = false;
        state.isSuccess = true;
        state.isCreated = true;
        state.message = payload;
      })
      .addCase(addTeamMember.rejected, (state, { payload }) => {
        state.isCreating = false;
        state.isError = true;
        state.message = payload;
        state.team = [];
      })
      .addCase(updateTeamMember.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateTeamMember.fulfilled, (state, { payload }) => {
        state.isUpdating = false;
        state.isSuccess = true;
        state.isUpdated = true;
        state.message = payload;
      })
      .addCase(updateTeamMember.rejected, (state, { payload }) => {
        state.isUpdating = false;
        state.isError = true;
        state.message = payload;
        state.team = [];
      })
      .addCase(deleteTeamMember.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(deleteTeamMember.fulfilled, (state, { payload }) => {
        state.isDeleting = false;
        state.isSuccess = true;
        state.isDeleted = true;
        state.message = payload;
      })
      .addCase(deleteTeamMember.rejected, (state, { payload }) => {
        state.isDeleting = false;
        state.isError = true;
        state.message = payload;
        state.team = [];
      });
  },
});

// Get all team members
export const getTeamMembers = createAsyncThunk(
  "get/teamMembers",
  async (thunkAPI) => {
    try {
      return await teamServices.getTeamMembers();
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Get all team member
export const getTeamMember = createAsyncThunk(
  "get/teamMember",
  async (id, thunkAPI) => {
    try {
      return await teamServices.getTeamMember(id);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Add Team Member
export const addTeamMember = createAsyncThunk(
  "add/teamMember",
  async (data, thunkAPI) => {
    try {
      return await teamServices.addTeamMember(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Update a team member
export const updateTeamMember = createAsyncThunk(
  "update/teamMember",
  async (data, thunkAPI) => {
    try {
      return await teamServices.updateTeamMember(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Delete a team member
export const deleteTeamMember = createAsyncThunk(
  "delete/teamMember",
  async (id, thunkAPI) => {
    try {
      return await teamServices.deleteTeamMember(id);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const { resetState } = teamSlice.actions;

export default teamSlice.reducer;
