import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./features/auth/authSlice";
import teamReducer from "./features/team/teamSlice";
import unitsReducer from "./features/units/unitsSlice";
import clientsReducer from "./features/clients/clientsSlice";
import settingsReducer from "./features/settings/settingsSlice";
import blogsReducer from "./features/blogs/blogsSlice";
import contactReducer from "./features/contact/contactSlice";
import vacanciesReducer from "./features/vacancies/vacanciesSlice";
import categoriesReducer from "./features/categories/categoriesSlice";
import publicationsReducer from "./features/publications/publicationsSlice";
import userDownloadHistoryReducer from "./features/userDownloadHistory/userDownloadHistorySlice";
import analyticsReducer from "./features/analytics/analyticsSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    team: teamReducer,
    units: unitsReducer,
    clients: clientsReducer,
    settings: settingsReducer,
    blogs: blogsReducer,
    contact: contactReducer,
    vacancies: vacanciesReducer,
    categories: categoriesReducer,
    publications: publicationsReducer,
    userDownloadHistory: userDownloadHistoryReducer,
    analytics: analyticsReducer,
  },
});
