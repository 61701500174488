let eventGuid = 0
// let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'Day meeting',
    start: "2022-10-11T08:30:00+02:00",
    end: "2022-10-11T09:30:00+02:00",
    patientName: "John Niyontwali",
    duration: 30,
    appointmentType: "Therapy Session",
    Location: "Office",
    Frequency: "Once"
  },
  {
    id: createEventId(),
    title: 'Meeting John',
    start: "2022-10-10T08:30:00+02:00",
    end: "2022-10-10T09:00:00+02:00",
    patientName: "John Niyontwali",
    duration: 30,
    appointmentType: "Therapy Session",
    Location: "Office",
    Frequency: "Once"
  },
  {
    id: createEventId(),
    title: 'Intro Meeting',
    start: "2022-10-10T02:30:00+02:00",
    end: "2022-10-10T03:30:00+02:30",
    patientName: "John Niyontwali",
    duration: 30,
    appointmentType: "Therapy Session",
    Location: "Office",
    Frequency: "Once"
  },
]

export function createEventId() {
  return String(eventGuid++)
}

