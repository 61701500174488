import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import { Empty } from "antd";

import { getAllPublications } from "../../../../states/features/publications/publicationsSlice";
import Spinner from "../../../../components/Spinner";
import RoundedBtn from "../../../../components/RoundedBtn";
import PublicationCard from "./PublicationCard";
import DefaultModal from "../../../../components/DefaultModal";
import Publication from "../../../../modalContents/Publication";

const Publications = () => {
  // local states
  const [open, setOpen] = useState(false);

  // dispatch
  const dispatch = useDispatch();

  // categories - redux state
  const { allPublications, isFetchingAll, isCreated, isUpdated, isDeleted } =
    useSelector((state) => state.publications);

  // useEffect
  useEffect(() => {
    dispatch(getAllPublications());
  }, [dispatch, isCreated, isUpdated, isDeleted]);

  // Store data in the memo
  const data = useMemo(() => allPublications?.data || [], [allPublications]);

  return (
    <>
      <div className="px-8 pt-12 md:pt-0">
        <div className="flex items-center justify-between">
          <h3 className="font-bold text-md mt-2">All Publications</h3>
          <RoundedBtn
            title="Add Publication"
            moreStyles="bg-primary text-white py-2 text-sm"
            onClick={() => setOpen(true)}
          />
        </div>
        <div className="min-h-[80vh]">
          {data.length === 0 && isFetchingAll ? (
            <Spinner />
          ) : (
            <>
              {data.length === 0 ? (
                <div className="min-h-[70vh] flex items-center justify-center">
                  <Empty />
                </div>
              ) : (
                <div className="mt-4 border p-5 rounded space-y-3">
                  {data &&
                    data.map((p) => (
                      <PublicationCard
                        title={p.title}
                        author={p.author}
                        category={p.Category.name}
                        date={p.createdAt}
                        id={p.id}
                      />
                    ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <DefaultModal
        modalTitle="Add Publication"
        open={open}
        width={450}
        setOpen={setOpen}
        modalContent={<Publication setOpen={setOpen} type="new" />}
      />
    </>
  );
};

export default Publications;
