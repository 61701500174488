import jwt_decode from "jwt-decode";

// decode token function
const parseJwt = (token) => {
  try {
    return jwt_decode(token);
  } catch (e) {
    return null;
  }
};

export default parseJwt;
