import { Empty } from "antd";

import Spinner from "../components/Spinner";
import CategroryPublicationsCard from "../components/CategroryPublicationsCard";

const CategoryPublications = ({ publications, isFetchingAll }) => {
  // URL
  const URL = process.env.REACT_APP_BASE_API_URL;

  return (
    <>
      {isFetchingAll ? (
        <Spinner moreStyles="pt-[10vh] pb-[20vh]" />
      ) : (
        <>
          {publications && publications.length === 0 ? (
            <div className="my-[10vh]">
              <Empty description="No publications yet" />
            </div>
          ) : (
            <main id="content" className="main">
              <section
                id="section-number-2"
                className="section-number-2 s-publication-overview">
                <div className="container s-publication-overview__inner -mt-[80px]">
                  <div className="row cards">
                    {publications &&
                      publications.map((publication) => (
                        <CategroryPublicationsCard
                          key={publication?.id}
                          id={publication?.id}
                          image={`${URL}/${publication?.image}`}
                          title={publication?.title}
                          author={publication?.author}
                          overview={publication?.overview}
                          documentLink={`${URL}/${publication?.document}`}
                          createdAt={publication?.createdAt}
                          slug={publication?.Category.slug}
                          categoryId={publication?.categoryId}
                        />
                      ))}
                  </div>
                </div>
              </section>
            </main>
          )}
        </>
      )}
    </>
  );
};

export default CategoryPublications;
