import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { AiOutlineCluster, AiOutlineUsergroupDelete } from "react-icons/ai";
import { MdOutlineCategory, MdOutlineManageAccounts } from "react-icons/md";
import { GrBlog, GrDocumentStore } from "react-icons/gr";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { HiOutlineUsers } from "react-icons/hi";
import { BiAnalyse } from "react-icons/bi";

// Footer - Quick Links
export const quickLinks = [
  {
    index: 1,
    name: "About Us",
    link: "about-us",
  },
  {
    index: 2,
    name: "Blogs",
    link: "blogs",
  },
  {
    index: 3,
    name: "Vacancies",
    link: "vacancies",
  },
];

// Footer - Social Links
export const socialLinks = [
  {
    index: 1,
    icon: <FaFacebookF size={20} />,
    link: "/",
  },
  {
    index: 2,
    icon: <FaTwitter size={20} />,
    link: "/",
  },
  {
    index: 3,
    icon: <FaYoutube size={18 == 20} />,
    link: "/",
  },
  {
    index: 4,
    icon: <FaLinkedinIn size={20} />,
    link: "/",
  },
];

// dashboard sidebar links
export const links = [
  {
    title: "Dashboard",
    links: [
      {
        id: 1,
        name: "Analytics",
        label: "analytics",
        icon: <BiAnalyse size={20} />,
      },
      {
        id: 2,
        name: "Team",
        label: "team",
        icon: <HiOutlineUsers size={20} />,
      },
      {
        id: 3,
        name: "Units",
        label: "units",
        icon: <AiOutlineCluster size={22} />,
      },
      {
        id: 4,
        name: "Categories",
        label: "categories",
        icon: <MdOutlineCategory size={22} />,
      },
      {
        id: 5,
        name: "Publications",
        label: "publications",
        icon: <GrDocumentStore size={22} />,
      },
      {
        id: 6,
        name: "Blogs",
        label: "blogs",
        icon: <GrBlog size={20} />,
      },
    ],
  },
  {
    title: "Others",
    links: [
      {
        id: 7,
        name: "Clients",
        label: "clients",
        icon: <AiOutlineUsergroupDelete size={22} />,
      },
      {
        id: 8,
        name: "Job Vacancies",
        label: "vacancies",
        icon: <MdOutlineManageAccounts size={22} />,
      },
      {
        id: 9,
        name: "Requests",
        label: "requests",
        icon: <IoIosHelpCircleOutline size={22} />,
      },
      {
        id: 10,
        name: "Company Settings",
        label: "account",
        icon: <MdOutlineManageAccounts size={22} />,
      },
    ],
  },
];

// gender
export const gender = ["Male", "Female", "Choose not to disclose", "Other"];

// local storage constants
export const lsUser = "brc_active_user";

// endpoint constants
export const loginEndpoint = "/login";
export const forgotpwEndpoint = "/forgot-password";
export const resetpwEndpoint = "/reset-password";
export const teamMemberEndpoint = "/team-members";
export const settingsEndpoint = "/company-settings";
export const blogEndpoint = "/blogs";
export const contactsEndpoint = "/contact-us";
export const unitEndpoint = "/units";
export const researchpapersEndpoint = "/research-papers";
export const vacanciesEndpoint = "/jobs";
export const clientEndpoint = "/clients";
export const policyBriefsEndpoint = "";
export const fieldSurveyEndpoint = "";
export const categoriesEndpoint = "/categories";
export const publicationsEndpoint = "/publications/category";
export const allPublicationsEndpoint = "/publications";
export const userDownloadHistoryEndpoint = "/user-download-history";
export const analyticsEndpoint = "/analytics";
