import Focus from "../sections/Focus";
import RecentBlogs from "../sections/RecentBlogs";
import { useTranslation } from "react-i18next";

import Work from "../sections/Work";
import HomeWrapper from "../components/HomeWrapper";
import { wrapper } from "../assets";
import InsideWrapper from "../components/InsideWrapper";
import ContactFloatButton from "../components/ContactFloatButton";

const HomeContainer = () => {
  const { t } = useTranslation();
  return (
    <main>
      <HomeWrapper
        title={t("homePage.wrapper.title")}
        description={t("homePage.wrapper.subtitle")}
        image={wrapper}
        alt="Home Page "
      />
      <Focus t={t} />
      <InsideWrapper t={t} />
      <Work t={t} />
      <RecentBlogs t={t} />
      <ContactFloatButton />
    </main>
  );
};

export default HomeContainer;
