import { Skeleton } from "antd";
import PropTypes from "prop-types";

const WrapperSchema = ({ moreStyles }) => {
  return (
    <section id="section-number-1" className="section-number-1 s-page-header">
      <div className="s-page-header__inner s-page-header__inner-- text-color-dark js-page-header">
        <div className="c-image s-page-header__background-image c-image--cover bg-gray-100"></div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 ">
              <div className={`content bg-white ${moreStyles}`}>
                <header
                  className="c-header s-page-header__header "
                  data-aos="fade-in"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-out"
                  data-aos-delay="50"
                >
                  <h1 className="c-heading c-header__heading c-heading--h2 ">
                    <Skeleton active />
                  </h1>
                </header>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WrapperSchema;

// Type Checking using PropTypes
WrapperSchema.propTypes = {
  moreStyles: PropTypes.string,
};
