import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import {
  createContactMessage,
  resetState,
} from "../states/features/contact/contactSlice";
import Spinner from "../components/Spinner";
import PrimaryBtn from "../components/PrimaryBtn";
import { notification } from "../utils";
import { Link } from "react-router-dom";

const Contact = () => {
  // dispatch
  const dispatch = useDispatch();

  // local states and react hook forms
  const [loading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // redux states
  const { isCreating, isCreated } = useSelector((state) => state.contact);

  const [consentChecked, setConsentChecked] = useState(false);

  // handle submit
  const onSubmit = (data) => {
    if (consentChecked) {
      dispatch(createContactMessage(data));
    } else {
      notification(
        "Please provide consent by agreeing to our Privacy Guidelines ",
        "warning",
        "bottomLeft",
      );
    }
  };

  useEffect(() => {
    if (isCreated) {
      reset({
        firstname: "",
        lastname: "",
        email: "",
        message: "",
      });
    }
    dispatch(resetState());
  }, [isCreated, reset, dispatch]);

  return (
    <section id="section-number-1" className="section-number-1 contact">
      <div className="container">
        <div className="row middle last contact">
          <div className="col-12 col-md-6 left">
            <header className="c-header s-contact__header">
              <h1 className="c-heading c-header__heading  s-contact__heading">
                Get in touch
              </h1>
            </header>
            {loading && <Spinner moreStyles="min-h-[0vh]" />}{" "}
            {/* Render the spinner when loading */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.569574262792!2d30.054843875361016!3d-1.9237634980587597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca614c562c2d9%3A0xaf1aaf3e8c22428c!2sPrivate%20Sector%20Federation!5e0!3m2!1sen!2srw!4v1682870361093!5m2!1sen!2srw"
              width="100%"
              height="450"
              title="BRC Location on Google map"
              style={{ border: 0, borderRadius: "10px" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              onLoad={() => setLoading(false)}
            />
          </div>

          <div className="col-12 col-md-5 right form-centre">
            <div className="formblock">
              <div
                className="gf_browser_chrome gform_wrapper gform_legacy_markup_wrapper gform-theme--no-framework"
                data-form-theme="legacy"
                data-form-index="0"
                id="gform_wrapper_5"
              >
                <div id="gf_5" className="gform_anchor" tabIndex="-1"></div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="gform-body gform_body">
                    <ul className="gform_fields top_label form_sublabel_below description_below">
                      <li className="gfield gfield--type-text gfield_contains_required field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible form__item">
                        <div className="ginput_container ginput_container_text">
                          <input
                            placeholder="First name*"
                            type="text"
                            className="medium w-full"
                            {...register("firstname", { required: true })}
                          />
                          {errors.firstname && (
                            <span className="text-red-700 text-[15px]">
                              First name is required
                            </span>
                          )}
                        </div>
                      </li>
                      <li
                        id="field_5_9"
                        className="gfield gfield--type-text gfield_contains_required field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible form__item"
                      >
                        <div className="ginput_container ginput_container_text">
                          <input
                            placeholder="Last name*"
                            type="text"
                            className="medium w-full"
                            {...register("lastname", { required: true })}
                          />
                          {errors.lastname && (
                            <span className="text-red-700 text-[15px]">
                              Last name is required
                            </span>
                          )}
                        </div>
                      </li>
                      <li className="gfield gfield--type-email gfield_contains_required field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible form__item">
                        <div className="ginput_container ginput_container_email">
                          <input
                            placeholder="Email*"
                            type="email"
                            className="medium w-full"
                            {...register("email", {
                              required: true,
                              pattern:
                                /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
                            })}
                          />

                          {(errors.email?.type === "required" && (
                            <span className="text-red-700 text-[15px]">
                              Email is required
                            </span>
                          )) ||
                            (errors.email?.type === "pattern" && (
                              <span className="text-red-700 text-[15px]">
                                Email is not valid
                              </span>
                            ))}
                        </div>
                      </li>
                      <li className="gfield gfield--type-textarea gfield_contains_required field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible form__item">
                        <div className="ginput_container ginput_container_textarea">
                          <textarea
                            placeholder="Message*"
                            className="textarea medium w-full"
                            rows="10"
                            cols="50"
                            {...register("message", {
                              required: true,
                            })}
                          ></textarea>
                          {errors.message && (
                            <span className="text-red-700 text-[15px]">
                              The Message field can't be empty
                            </span>
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="flex items-center space-x-2 mb-3">
                    <input
                      type="checkbox"
                      checked={consentChecked}
                      onChange={(e) => setConsentChecked(e.target.checked)}
                    />
                    <Link
                      to="/privacy-guidelines"
                      htmlFor="consentCheckbox"
                      className="text-[15px] no-underline"
                    >
                      I agree to the BRC Privacy Guidelines
                    </Link>
                  </div>

                  <PrimaryBtn
                    type="submit"
                    moreStyles="bg-primary ml-auto w-full"
                    title={"Send"}
                    isLoading={isCreating}
                    loadingTitle={"Sending Message"}
                    disabled={isCreating}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
