import PropTypes from "prop-types";

const HomeWrapper = ({ title, description, image, alt, moreStyles }) => {
  return (
    <section id="section-number-1" className="section-number-1 s-page-header">
      <div className="s-page-header__inner s-page-header__inner-- text-color-dark js-page-header">
        <div className="c-image s-page-header__background-image c-image--cover">
          <img
            className="attachment-s-page-header__header size-s-page-header__header wp-post-image c-image__img"
            alt={alt}
            src={image}
            loading="lazy"
          />
        </div>
        <div className="flex justify-center w-full">
          <div className=" flex justify-center w-full md:w-[50%]">
            <div className="bg-[#cfddf0] md:bg-white px-4 py-2 rounded-[10px]  md:opacity-[0.85] mt-10 md:mt-[400px]">
              <header className="s-page-header__header ">
                <h1 className="text-[30px] mb-0 text-center">{title}</h1>
              </header>

              <div
                className="text-md text-center"
                data-aos="fade-in"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
                data-aos-delay="50"
              >
                <p className="text-[20px]">{description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeWrapper;

// Type Checking using PropTypes
HomeWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  quote: PropTypes.string,
  moreStyles: PropTypes.string,
};
