import { MdOutlineCancel } from "react-icons/md";

import { links } from "../../../constants";
import { darkLogo } from "../../../assets";

const Sidebar = ({ activeMenu, setActiveMenu, index, setIndex }) => {
  const activeLink =
    "bg-floor cursor-disabled flex items-center space-x-5 px-4 py-2 rounded-lg  text-primary  text-md m-2 cursor-pointer";
  const normalLink =
    "flex items-center space-x-5 pl-4 py-2 rounded-lg text-md text-dark hover:text-primary hover:bg-floor m-2 cursor-pointer";

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <div className="flex items-center  space-x-3 mt-4 text-md font-[Gordita-Bold] tracking-tight text-primary">
              <img src={darkLogo} alt="edc-logo" width={"50%"} loading="lazy" />
            </div>
            <button
              type="button"
              onClick={() => setActiveMenu(!activeMenu)}
              className="text-primary text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden">
              <MdOutlineCancel />
            </button>
          </div>
          <div className="mt-10 ">
            {links.map((item) => (
              <div key={item.title}>
                <h4 className="text-dark  mx-3 my-6 uppercase text-[15px]">
                  {item.title}
                </h4>
                {item.links.map((link) => {
                  return (
                    <div
                      key={link.name}
                      onClick={() => setIndex(link.id)}
                      className={link.id === index ? activeLink : normalLink}>
                      {link.icon}
                      <span className="capitalize hover:text-primary">
                        {link.name}
                      </span>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
