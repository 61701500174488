import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCategories } from "../states/features/categories/categoriesSlice";
import Spinner from "../components/Spinner";
import { Empty } from "antd";
import PublicationsCategoriesCard from "../components/PublicationsCategoriesCard";

const PublicationsCategories = () => {
  // URL
  const URL = process.env.REACT_APP_BASE_API_URL;

  // dispatch
  const dispatch = useDispatch();

  // redux
  const { categories, isFetchingAll } = useSelector(
    (state) => state.categories,
  );

  // useEffect
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  return (
    <>
      {isFetchingAll ? (
        <Spinner moreStyles="pt-[10vh] pb-[20vh]" />
      ) : (
        <>
          {categories?.data && categories?.data.length === 0 ? (
            <div className="my-[10vh]">
              <Empty description="No publication categories yet" />
            </div>
          ) : (
            <main id="content" className="main">
              <section
                id="section-number-2"
                className="section-number-2 s-publication-overview"
              >
                <div className="container s-publication-overview__inner">
                  <div className="row cards">
                    {categories?.data &&
                      categories?.data.map((category) => (
                        <PublicationsCategoriesCard
                          key={category?.id}
                          image={`${URL}/${category?.image}`}
                          name={category?.name}
                          description={category?.description}
                          slug={category?.slug}
                          categoryId={category?.id}
                        />
                      ))}
                  </div>
                </div>
              </section>
            </main>
          )}
        </>
      )}
    </>
  );
};

export default PublicationsCategories;
