import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlogs } from "../states/features/blogs/blogsSlice";

import BlogCard from "../components/BlogCard";
import Spinner from "../components/Spinner";
import { Empty } from "antd";

const Blog = () => {
  // dispatch
  const dispatch = useDispatch();

  // staff
  const { blogs, isFetchingAll, message } = useSelector((state) => state.blogs);
  // useEffect
  useEffect(() => {
    dispatch(getBlogs());
  }, [message, dispatch]);

  return (
    <section id="section-number-2" className="section-number-2 s-blog-overview">
      <div className="container s-blog-overview__wrap">
        <div className="row posts s-blog-overview__posts">
          {isFetchingAll ? (
            <Spinner moreStyles="min-h-[30vh]" />
          ) : (
            <>
              {blogs?.data && blogs.data.length === 0 ? (
                <div className="my-[10vh] flex items-center justify-center w-full">
                  <Empty description="No blogs yet" />
                </div>
              ) : (
                <div className="facetwp-template" data-name="blog">
                  {blogs?.data &&
                    blogs?.data.map((blog) => (
                      <BlogCard blog={blog} key={blog.id} />
                    ))}
                </div>
              )}
            </>
          )}
        </div>
        <div className="col-12 text-center pagercol s-blog-overview__pagination">
          <div className="facetwp-pager"></div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
