import { Empty } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../../components/Spinner";
import BlogCard from "../../../../components/BlogCard";
import RoundedBtn from "../../../../components/RoundedBtn";
import { getBlogs } from "../../../../states/features/blogs/blogsSlice";
import BlogEditor from "./BlogEditor";
import BlogPreview from "./BlogPreview";

const Blogs = () => {
  // local states
  const [index, setIndex] = useState(0);
  const [content, setContent] = useState("");

  // dispatch
  const dispatch = useDispatch();

  // staff
  const { blogs, isFetchingAll, message } = useSelector((state) => state.blogs);

  // useEffect
  useEffect(() => {
    dispatch(getBlogs());
  }, [message, dispatch]);

  return (
    <>
      <div className="px-8 pt-12 md:pt-0">
        <div className="flex items-center justify-between">
          <h3 className="font-bold text-md mt-2">All Blogs</h3>
          {index === 0 && (
            <RoundedBtn
              title="Add Blog"
              moreStyles="bg-primary text-white py-2 text-sm"
              onClick={() => setIndex(1)}
            />
          )}
          {index === 1 && (
            <div className="flex items-center space-x-3">
              <RoundedBtn
                title="Preview"
                moreStyles="bg-[#f68920] text-white py-2 text-sm"
                onClick={() => setIndex(2)}
              />
              <RoundedBtn
                title="Cancel"
                moreStyles="bg-floor text-gray-600 py-2 text-sm"
                onClick={() => setIndex(0)}
              />
            </div>
          )}
          {index === 2 && (
            <RoundedBtn
              title="Back to Editor"
              moreStyles="bg-floor text-gray-600 py-2 text-sm"
              onClick={() => setIndex(1)}
            />
          )}
        </div>
        {index === 0 && (
          <div className="row posts s-blog-overview__posts">
            {isFetchingAll ? (
              <Spinner />
            ) : (
              <>
                {blogs?.data && blogs?.data.length === 0 ? (
                  <div className="min-h-[70vh] flex items-center justify-center w-full">
                    <Empty description="No Blogs" />
                  </div>
                ) : (
                  <div className="facetwp-template" data-name="blog">
                    {blogs?.data &&
                      blogs?.data.map((blog) => (
                        <BlogCard blog={blog} key={blog.id} />
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {index === 1 && (
          <BlogEditor
            type="new"
            content={content}
            setContent={setContent}
            setIndex={setIndex}
          />
        )}
        {index === 2 && (
          <BlogPreview content={content} setContent={setContent} />
        )}
      </div>
    </>
  );
};

export default Blogs;
