import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { darkLogo } from "../assets";
import { login, resetState } from "../states/features/auth/authSlice";
import { ImSpinner2 } from "react-icons/im";
import { notification } from "../utils";
import ContactFloatButton from "../components/ContactFloatButton";

const LoginContainer = () => {
  // translations
  const { t } = useTranslation();

  // react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redux states
  const { user, isSuccess, isError, isLoading, message } = useSelector(
    (state) => state.auth,
  );

  // handle submission
  const onSubmit = (data) => {
    dispatch(login(data));
  };

  // useEffect
  useEffect(() => {
    if (isError) {
      notification(message, "error", "bottomLeft");
    }
    if (isSuccess || user) {
      if (user?.role === "admin") {
        navigate("/dashboard");
      } else {
        return null;
      }
    }
    dispatch(resetState());
  }, [isSuccess, isError, user, message, navigate, dispatch]);

  return (
    <div className="login-container">
      <Form className="login-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="logo-container">
          <Link to="/">
            <img
              src={darkLogo}
              alt="logo"
              className="logo-login"
              loading="lazy"
            />
          </Link>
          <p>
            {t("loginPage.titlePart1")}
            <br /> {t("loginPage.titlePart2")}
          </p>
        </div>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>{t("loginPage.emailLabel")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("loginPage.emailPlaceholder")}
            {...register("email", {
              required: true,
              pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
            })}
          />
          {(errors.email?.type === "required" && (
            <span className="text-red-700 text-[13px]">Email is required</span>
          )) ||
            (errors.email?.type === "pattern" && (
              <span className="text-red-700 text-[13px]">
                Email is not valid
              </span>
            ))}
        </Form.Group>

        <Form.Group controlId="formBasicPassword" className="mt-3">
          <Form.Label>{t("loginPage.passwordLabel")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("loginPage.passwordPlaceholder")}
            {...register("password", {
              required: true,
            })}
          />
          {errors.password?.type === "required" && (
            <span className="text-red-700 text-[13px]">
              Password is required
            </span>
          )}
        </Form.Group>

        <Button variant="primary" type="submit" disabled={isLoading}>
          {!isLoading ? (
            t("loginPage.buttonText")
          ) : (
            <span className="flex justify-center items-center">
              Loging you in
              <ImSpinner2 size={20} className="animate-spin ml-2" />
            </span>
          )}
        </Button>
        <div className="text-center mt-3">
          <div>
            {" "}
            <span className="text-[16px]">Forgot your password</span>, click{" "}
            <Link to="/forgot-password">here</Link>
          </div>
        </div>
      </Form>
      <ContactFloatButton />
    </div>
  );
};

export default LoginContainer;
