import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import { Empty } from "antd";

import { getCategories } from "../../../states/features/categories/categoriesSlice";
import Spinner from "../../../components/Spinner";
import RoundedBtn from "../../../components/RoundedBtn";
import DefaultModal from "../../../components/DefaultModal";
import Category from "../../../modalContents/Category";
import DateFormater from "../../../helpers/DateFormater";
import Actions from "../actions/categories/Actions";
import Table from "../../../components/Table";
import truncateString from "../../../utils/truncateString";

const Categories = () => {
  // URL
  const URL = process.env.REACT_APP_BASE_API_URL;

  // local states
  const [open, setOpen] = useState(false);

  // dispatch
  const dispatch = useDispatch();

  // categories - redux state
  const { categories, isFetchingAll, isCreated, isUpdated, isDeleted } =
    useSelector((state) => state.categories);

  // useEffect
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch, isCreated, isUpdated, isDeleted]);

  const columns = useMemo(
    () => [
      {
        Header: "Category Name",
        accessor: "name",
        Cell: ({ cell }) => (
          <span
            style={{
              fontFamily: "GT-Walsheim-Regular",
              fontSize: "15px",
              color: "#374151",
            }}
          >
            {cell.value}
          </span>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ cell }) => (
          <span
            title={cell.row.original.description}
            style={{
              fontFamily: "GT-Walsheim-Regular",
              fontSize: "15px",
              color: "#374151",
            }}
          >
            {truncateString(cell.row.original.description, 25)}
          </span>
        ),
      },
      {
        Header: "Image",
        accessor: "image",
        Cell: ({ cell }) => (
          <a
            href={`${URL}/${cell.row.original.image}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${URL}/${cell.row.original.image}`}
              alt="Logo"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                objectFit: "cover"
              }}
              title="Click to open"
            />
          </a>
        ),
      },
      {
        Header: "Created On",
        accessor: "createdAt",
        Cell: DateFormater,
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: Actions,
      },
    ],
    [URL],
  );

  // Store data in the memo
  const data = useMemo(() => categories?.data || [], [categories]);

  return (
    <>
      <div className="px-8 pt-12 md:pt-0">
        <div className="flex items-center justify-between">
          <h3 className="font-bold text-md mt-2">All Publication Categories</h3>
          <RoundedBtn
            title="Add Publication Category"
            moreStyles="bg-primary text-white py-2 text-sm"
            onClick={() => setOpen(true)}
          />
        </div>
        <div className="min-h-[80vh]">
          {data.length === 0 && isFetchingAll ? (
            <Spinner />
          ) : (
            <>
              {data.length === 0 ? (
                <div className="min-h-[70vh] flex items-center justify-center">
                  <Empty />
                </div>
              ) : (
                <div className="mt-4">
                  <Table columns={columns} data={data} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <DefaultModal
        modalTitle="Add Publication Category"
        open={open}
        width={450}
        setOpen={setOpen}
        modalContent={<Category setOpen={setOpen} type="new" />}
      />
    </>
  );
};

export default Categories;
