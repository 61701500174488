import API from "../../../api/api";
import {
  publicationsEndpoint,
  allPublicationsEndpoint,
} from "../../../constants";
import { notification } from "../../../utils";

// Get all publications
const getAllPublications = async () => {
  const response = await API.get(allPublicationsEndpoint);
  return response.data;
};

// Get all publications in a category
const getPublications = async (id) => {
  const response = await API.get(`${publicationsEndpoint}/${id}`);
  return response.data;
};

// Get a publication
const getPublication = async (id) => {
  const response = await API.get(`${publicationsEndpoint}/${id}`);
  return response.data;
};

// Get a publication
const getSinglePublication = async (id) => {
  const response = await API.get(`${allPublicationsEndpoint}/${id}`);
  return response.data;
};

// Add a publication
const addPublication = async (data) => {
  const response = await API.post(allPublicationsEndpoint, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// Update a publication
const updatePublication = async ({
  id,
  fullname,
  email,
  phone,
  position,
  category,
  bio,
  image,
}) => {
  const response = await API.put(`${publicationsEndpoint}/${id}`, {
    fullname,
    email,
    phone,
    position,
    category,
    bio,
    image,
  });
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// Delete a publication
const deletePublication = async (id) => {
  const response = await API.delete(`${allPublicationsEndpoint}/${id}`);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

const publicationsServices = {
  getAllPublications,
  getPublications,
  getPublication,
  getSinglePublication,
  addPublication,
  updatePublication,
  deletePublication,
};

export default publicationsServices;
