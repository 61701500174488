import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const SUPPORTED_LANGUAGES = ["en", "fr", "rw"];
const FALLBACK_LANGUAGE = "en";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: SUPPORTED_LANGUAGES,
    fallbackLng: FALLBACK_LANGUAGE,
    preload: SUPPORTED_LANGUAGES,
    keySeparator: ".",
    detection: {
      order: ["localStorage", "path", "htmlTag", "subdomain"],
      caches: ["localStorage"],
      excludeCacheFor: ["cimode"], // exclude language from being stored in cache
      localStorageKey: "brc-web-language",
    },
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
  });

export default i18n;
