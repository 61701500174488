import React from "react";
import { useNavigate } from "react-router-dom";
import { FloatButton } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const ContactFloatButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleButtonClick = () => {
    navigate("/contact-us");
  };

  return (
    <FloatButton
      className="mb-2"
      onClick={handleButtonClick}
      type="primary"
      icon={<MailOutlined className="flex items-center justify-center" />}
      tooltip={<div>{t("homePage.contact")}</div>}
    />
  );
};

export default ContactFloatButton;
