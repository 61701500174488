import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import PrimaryBtn from "../components/PrimaryBtn";
import {
  addTeamMember,
  updateTeamMember,
  resetState,
  getTeamMember,
} from "../states/features/team/teamSlice";
import Spinner from "../components/Spinner";

const TeamMember = ({ setOpen, type, id }) => {
  // URL
  const URL = process.env.REACT_APP_BASE_API_URL;

  // local states and react hook form
  const { register, handleSubmit, setValue, formState } = useForm({
    mode: "all",
  });
  const { errors } = formState;

  const {
    teamMember,
    isCreating,
    isCreated,
    isUpdating,
    isUpdated,
    isFetchingOne,
  } = useSelector((state) => state.team);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      console.log("id", id);
      dispatch(getTeamMember(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (teamMember?.data && type !== "new") {
      setValue("fullname", teamMember?.data?.fullname);
      setValue("email", teamMember?.data?.email);
      setValue("phone", teamMember?.data?.phone);
      setValue("position", teamMember?.data?.position);
      setValue("category", teamMember?.data?.category);
      setValue("bio", teamMember?.data?.bio);
    }
  }, [teamMember?.data, setValue, type, id]);

  useEffect(() => {
    if (isCreated || isUpdated) {
      setOpen(false);
      dispatch(resetState());
    }
  }, [dispatch, isCreated, isUpdated, setOpen]);

  const onSubmit = (data) => {
    const { fullname, email, phone, position, category, bio, image } = data;
    const imageData = image[0];

    if (type === "new") {
      dispatch(
        addTeamMember({
          fullname,
          email,
          phone,
          position,
          category,
          bio,
          image: imageData,
        }),
      );
    } else {
      dispatch(
        updateTeamMember({
          id,
          fullname,
          email,
          phone,
          position,
          category,
          bio,
          image: imageData,
        }),
      );
    }
  };

  return (
    <>
      {isFetchingOne ? (
        <Spinner moreStyles="min-h-[20px] mb-[50px]" />
      ) : (
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <label className="text-[14px]">Fullname</label>
            <input
              className="text-input w-full"
              type="text"
              name="fullname"
              {...register("fullname", {
                required: "Fullname is required",
              })}
            />
            {errors.fullname && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.fullname.message}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <label className="text-[14px]">Email</label>
            <input
              className="text-input w-full"
              type="text"
              name="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  message: "Email is not valid",
                },
              })}
            />
            {errors.email && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.email.message}
              </span>
            )}
          </div>

          <div className="flex flex-col">
            <label className="text-[14px]">Phone number</label>
            <input
              className="text-input w-full"
              type="number"
              name="phone"
              {...register("phone", {
                required: "Phone number is required",
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: "Phone number must have 10 digits",
                },
              })}
            />
            <span className="text-red-600 text-[13px] mb-2 -mt-2">
              {errors.phone && errors.phone.message}
            </span>
          </div>

          <div className="flex flex-col">
            <label className="text-[14px]">Position</label>
            <input
              className="text-input w-full"
              type="text"
              name="position"
              {...register("position", {
                required: "Position is required",
              })}
            />
            {errors.position && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.position.message}
              </span>
            )}
          </div>

          <div className="flex flex-col">
            <label className="text-[14px]">Team Member Category</label>
            <select
              className="text-input w-full"
              name="category"
              {...register("category", {
                required: "Team member category is required",
              })}
            >
              <option value="">Select an option</option>
              <option value="BoD">Board of Directors</option>
              <option value="Office Staff">Office Staff</option>
              <option value="Fellow">Fellow</option>
            </select>
            {errors.category && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.category.message}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <label className="text-[14px]">Bio</label>
            <textarea
              className="text-input w-full"
              type="text"
              name="bio"
              {...register("bio", { required: "Bio is required" })}
            />
            {errors.bio && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.bio.message}
              </span>
            )}
          </div>

          <div className="flex flex-col">
            <label className="text-[14px]">Profile Picture</label>
            {type !== "new" && (
              <div className="mb-3">
                {teamMember?.data && (
                  <img
                    src={`${URL}/${teamMember?.data?.image}`}
                    alt={teamMember?.data?.fullname}
                    className="w-20 h-20 rounded-full object-cover"
                    loading="lazy"
                  />
                )}
              </div>
            )}
            <input
              className="mb-3"
              type="file"
              name="image"
              // hidden={type !== "new"}
              accept="image/*"
              {...register("image", {
                ...(type === "new" && { required: "Image is required" }),
              })}
            />
            {errors.image && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.image.message}
              </span>
            )}
          </div>

          <div className="mt-2">
            <PrimaryBtn
              type="submit"
              moreStyles="bg-primary ml-auto"
              title={type === "new" ? "Add Team Member" : "Update Team Member"}
              isLoading={type === "new" ? isCreating : isUpdating}
              loadingTitle="Adding a team member"
            />
          </div>
        </form>
      )}
    </>
  );
};

export default TeamMember;
