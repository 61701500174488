import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "../states/features/settings/settingsSlice";
import Spinner from "../components/Spinner";
import { mission } from "../assets";

const Mission = () => {
  // dispatch
  const dispatch = useDispatch();

  // redux
  const { settings, isLoading } = useSelector((state) => state.settings);

  // cache data until changed
  const data = useMemo(() => settings?.data || [], [settings?.data]);

  // useEffect
  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  return (
    <section
      id="section-number-3"
      className="section-number-3 s-content-image  section--bg-color"
    >
      <div className="container">
        <div className="row ">
          <div className="col-12 col-md-5    s-content-image__image--aligned-center  s-content-image__image">
            <div className="c-image">
              <img
                className="rounded-[10px]"
                alt="mission wrapper"
                src={mission}
                sizes="480px, 480px"
              />
            </div>
          </div>
          <div className="col-12 col-md-6  offset-md-1  s-content-image__content  s-content-image__content--aligned-center ">
            <header className="c-header">
              <h3 className="c-heading c-header__heading c-heading--h2 ">
                Our Mission
              </h3>
            </header>
            {data.length === 0 && isLoading ? (
              <Spinner moreStyles="my-0" />
            ) : (
              <div className="c-content s-content-image__content">
                {data[0] && (
                  <p className="text-justify">{data[0].companyMission}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
