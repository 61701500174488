import PropTypes from "prop-types";

const PrimaryTextArea = ({ label, value, name, onChange }) => {
  return (
    <div>
      <label className="form-label">{label}</label>
      <textarea
        className="account-form-input"
        type="text"
        value={value}
        name={name}
        onChange={onChange}
        cols={90}
        rows={4}
      />
    </div>
  );
};

export default PrimaryTextArea;

PrimaryTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
