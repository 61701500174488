import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { INITIAL_EVENTS } from "../utils/event";
import ContactFloatButton from "../components/ContactFloatButton";
import NoteTag from "../components/NoteTag";

const { confirm } = Modal;

const SchedulesContainer = ({
  editable = false,
  selectable = false,
  selectMirror = false,
}) => {
  // local states

  // Appointments State
  // const { appointments, isLoading } = useSelector(
  //   (state) => state.appointments
  // );

  // initialization of dispatch
  // const dispatch = useDispatch();

  // Use effect of getting all appointments
  // useEffect(() => {
  //   dispatch(getAppointments());
  // }, [dispatch]);

  // handle event click

  const showDeleteConfirm = (clickInfo) => {
    confirm({
      title: "Are you sure delete this appointment?",
      icon: <ExclamationCircleOutlined />,
      content: clickInfo.event.title,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        clickInfo.event.remove();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // handle date select
  const handleSchedulePeriod = (periodSelected) => {
    // setSelectedInfo(periodSelected);
    // setOpenModal((prev) => !prev);
  };

  // // render event content
  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  };

  return (
    <div className="mt-[8rem] mb-[5rem] px-[40px] sm:px-[120px]">
      <div className="max-w-[36rem] mb-10 mx-auto">
        <NoteTag
          type="info"
          text="Explore our calendar for upcoming BRC events and be part of the excitement! 🗓️"
        />
      </div>
      <div className="calendarContainer ">
        <div className="calendarSubContainer">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "timeGridWeek,timeGridDay,dayGridMonth",
            }}
            initialView="timeGridWeek"
            editable={editable}
            selectable={selectable}
            selectMirror={selectMirror}
            dayMaxEvents={true}
            weekends={true}
            initialEvents={INITIAL_EVENTS}
            select={handleSchedulePeriod}
            eventContent={renderEventContent}
            // custom render function
            eventClick={showDeleteConfirm}
            // eventsSet={handleEvents}
            height="80vh"
            allDaySlot={false}
          />
        </div>
      </div>
      {/* {openModal && (
        <Appointment setOpenModal={setOpenModal} selectedInfo={selectedInfo} />
      )} */}
      <ContactFloatButton />
    </div>
  );
};

export default SchedulesContainer;
