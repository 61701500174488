import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authServices";
import { lsUser } from "../../../constants";

// Get user from local storage
const user = JSON.parse(localStorage.getItem(lsUser));

// Initial State for the auth Slice
const initialState = {
  user: user ? user : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Auth slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = payload;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
        state.user = null;
      })
      .addCase(forgotpw.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotpw.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload;
      })
      .addCase(forgotpw.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      })
      .addCase(resetpw.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetpw.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload;
      })
      .addCase(resetpw.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
      });
  },
});

// Login
export const login = createAsyncThunk("admin/login", async (data, thunkAPI) => {
  try {
    return await authService.login(data);
  } catch (error) {
    let message;
    if (error.code === "ERR_NETWORK") {
      message = error.message;
    } else if (error.response) {
      if (error.response.data.message) {
        message = error.response.data.message;
      }
    }
    return thunkAPI.rejectWithValue(message);
  }
});

// forgot password
export const forgotpw = createAsyncThunk(
  "admin/forgotpw",
  async (data, thunkAPI) => {
    try {
      return await authService.forgotpw(data);
    } catch (error) {
      let message;
      if (error.code === "ERR_NETWORK") {
        message = error.message;
      } else if (error.response) {
        if (error.response.data.message) {
          message = error.response.data.message;
        }
      }
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// reset password
export const resetpw = createAsyncThunk(
  "admin/reset",
  async (data, thunkAPI) => {
    try {
      return await authService.resetpw(data);
    } catch (error) {
      let message;
      if (error.code === "ERR_NETWORK") {
        message = error.message;
      } else if (error.response) {
        if (error.response.data.message) {
          message = error.response.data.message;
        }
      }
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Logout
export const logout = createAsyncThunk("logout", async () => {
  await authService.logout();
});

export const { resetState } = authSlice.actions;
export default authSlice.reducer;
