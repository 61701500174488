import React from "react";
import Wrapper from "../components/Wrapper";
import { dataWrapper } from "../assets";
import OurData from "../sections/OurData";
import ContactFloatButton from "../components/ContactFloatButton";

const DataContainers = () => {
  return (
    <main>
      <Wrapper
        title="Data"
        description="BRC excels in data collection with a skilled, trustworthy team. We ensure accurate information through rigorous training and tailored methods."
        image={dataWrapper}
        alt="Data Page Wrapper"
      />
      <OurData />
      <ContactFloatButton />
    </main>
  );
};

export default DataContainers;
