import React from "react";
import { utilizingData } from "../assets";

const BusinessAnalytics = () => {
  return (
    <main>
      <section
        id="section-number-3"
        className="section-number-3 s-content-image  "
      >
        <div className="container">
          <div className="row  h-reverse-order--row ">
            <div className="col-12 col-md-5  offset-md-1   s-content-image__image--aligned-center  s-content-image__image">
              <div className="c-image ">
                <img
                  className="attachment-s-content-image__image size-s-content-image__image wp-post-image c-image__img"
                  alt="Predictive analytics"
                  src={utilizingData}
                  sizes="480px, 480px"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-12 col-md-6   s-content-image__content  s-content-image__content--aligned-center ">
              <header className="c-header">
                <h3 className="c-heading c-header__heading c-heading--h2">
                  Utilizing Data Analysis and Business Models for Informed
                  Advocacy
                </h3>
              </header>

              <div className="c-content s-content-image__content">
                <p className="text-justify">
                  The BRC is committed to transforming data into actionable
                  advocacy. Through a range of analytical services, we support
                  the private sector, offering market research, sector reports,
                  data visualization, policy assessment, competitor analysis,
                  consumer insights, economic impact studies, supply chain
                  optimization, investment feasibility studies, training, and
                  customized research. By bridging data and informed
                  decision-making, we empower businesses and policymakers to
                  promote sustainable economic growth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BusinessAnalytics;
