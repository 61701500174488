import React from "react";
import {  whoWeAre } from "../assets";

const InsideWrapper = ({ image, title, subtitle, description }) => {
  return (
    <section
      id="section-number-3"
      className="section-number-3 s-content-image  section--bg-color"
    >
      <div className="container">
        <div className="row ">
          <div className="col-12 col-md-5    s-content-image__image--aligned-center  s-content-image__image">
            <div className="c-image ">
              <img
                className="attachment-s-content-image__image size-s-content-image__image wp-post-image c-image__img"
                alt="map of rwanda"
                src={whoWeAre}
                loading="lazy"
              />
            </div>
          </div>
          <div className="col-12 col-md-6  offset-md-1  s-content-image__content  s-content-image__content--aligned-center ">
            <header className="c-header">
              <span className="c-subheading c-header__subheading  ">
                Who we are
              </span>

              <h3 className="c-heading c-header__heading c-heading--h2 ">
                Empowering businesses through evidence-based research
              </h3>
            </header>

            <div className="c-content s-content-image__content">
              <p className="text-justify">
                BRC has over 20 years of extensive experience in data collection
                and development research in Rwanda. It started as a Research
                Unit under the Advocacy Department of PSF. We work on projects
                focused on the Rwandan business community through five clusters:
                Industry, Agriculture, Trade, Services, and a specialized
                cluster for youth, women, and persons with disabilities."
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InsideWrapper;
