import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import PrimaryBtn from "../components/PrimaryBtn";
import Spinner from "../components/Spinner";
import { getCategories } from "../states/features/categories/categoriesSlice";
import {
  addPublication,
  updatePublication,
  resetState,
} from "../states/features/publications/publicationsSlice";

const Publication = ({ setOpen, type, id }) => {
  // URL
  const URL = process.env.REACT_APP_BASE_API_URL;

  // local states and react hook form
  const { register, handleSubmit, setValue, formState } = useForm({
    mode: "all",
  });
  const { errors } = formState;

  const {
    publications,
    isCreating,
    isCreated,
    isUpdating,
    isUpdated,
    isFetchingOne,
  } = useSelector((state) => state.publications);

  const { categories, isFetchingOne: isLoading } = useSelector(
    (state) => state.categories
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (publications?.data && type !== "new") {
      setValue("title", publications?.title);
      setValue("categoryId", publications?.data?.categoryId);
      setValue("author", publications?.data?.author);
      setValue("overview", publications?.data?.overview);
    }
  }, [publications?.data, publications?.title, setValue, type]);

  const onSubmit = (data) => {
    const { title, categoryId, author, image, overview } = data;

    if (type === "new") {
      dispatch(
        addPublication({
          title,
          categoryId,
          author,
          image: image[0],
          overview,
        })
      );
    } else {
      dispatch(
        updatePublication({
          title,
          categoryId,
          author,
          image: image[0],
          overview,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    if (isCreated || isUpdated) {
      setOpen(false);
      dispatch(resetState());
    }
  }, [dispatch, isCreated, isUpdated, setOpen]);

  return (
    <>
      {isFetchingOne || isLoading ? (
        <Spinner moreStyles="min-h-[20px] mb-[50px]" />
      ) : (
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <label className="text-[14px]">Publication Title</label>
            <input
              className="text-input w-full"
              type="text"
              name="title"
              {...register("title", {
                required: "Publication Title is required",
              })}
            />
            {errors.title && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.title.message}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <label className="text-[14px]">Publication Category</label>
            <select
              className="text-input w-full"
              name="categoryId"
              {...register("categoryId", {
                required: "Publication Category is required",
              })}>
              <option value="">Select a category</option>
              {categories?.data &&
                categories?.data.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </select>
            {errors.categoryId && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.categoryId.message}
              </span>
            )}
          </div>

          <div className="flex flex-col">
            <label className="text-[14px]">Publication Author</label>
            <input
              className="text-input w-full"
              type="text"
              name="author"
              {...register("author", {
                required: "Publication Author is required",
              })}
            />
            <span className="text-red-600 text-[13px] mb-2 -mt-2">
              {errors.author && errors.author.message}
            </span>
          </div>

          <div className="flex flex-col">
            <label className="text-[14px]">Publication Overview</label>
            <textarea
              className="text-input w-full"
              type="text"
              name="overview"
              {...register("overview")}
            />
          </div>

          <div className="flex flex-col">
            <label className="text-[14px]">Publication Cover Image</label>
            {type !== "new" && (
              <div className="mb-3">
                {publications?.data && (
                  <img
                    src={`${URL}/${publications?.data?.image}`}
                    alt={publications?.data?.fullname}
                    className="w-20 h-20 rounded-full object-cover"
                    loading="lazy"
                  />
                )}
              </div>
            )}
            <input
              className="mb-3"
              type="file"
              name="image"
              hidden={type !== "new"}
              accept="image/*"
              {...register("image", {
                ...(type === "new" && { required: "Image is required" }),
              })}
            />
            {errors.image && (
              <span className="text-red-600 text-[13px] mb-2 -mt-2">
                {errors.image.message}
              </span>
            )}
          </div>
          <div className="mt-2">
            <PrimaryBtn
              type="submit"
              moreStyles="bg-primary ml-auto"
              title={type === "new" ? "Add Publication" : "Update Publication"}
              isLoading={type === "new" ? isCreating : isUpdating}
              loadingTitle="Adding a team member"
            />
          </div>
        </form>
      )}
    </>
  );
};

export default Publication;
