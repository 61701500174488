import { useTranslation } from "react-i18next";

import { wellInformedAdvocacy } from "../assets";

const Introduction = () => {
  const { t } = useTranslation();
  return (
    <section
      id="section-number-2"
      className="section-number-2 s-content-image  "
    >
      <div className="container">
        <div className="row h-reverse-order--row ">
          <div className="col-12 col-md-5  offset-md-1   s-content-image__image--aligned-center  s-content-image__image">
            <div className="c-image slide-up pb-10 ">
              <img
                className="attachment-s-content-image__image size-s-content-image__image wp-post-image c-image__img object-cover"
                alt="business data wrapper illustration"
                src={wellInformedAdvocacy}
              />
            </div>
          </div>
          <div className="col-12 col-md-6   s-content-image__content  s-content-image__content--aligned-center ">
            <header className="c-header">
              <span className="c-subheading c-header__subheading  ">
                {t("homeIntro.title")}
              </span>

              <h3 className="c-heading c-header__heading c-heading--h2 ">
                From data to well-informed advocacy
              </h3>
            </header>

            <div className="c-content s-content-image__content">
              <p className="text-justify">
                The BRC is the research arm of the Private Sector Federation
                (PSF), which is an umbrella organization aimed at promoting and
                representing the interests of the business community in Rwanda.
                The centre is dedicated to carrying out business research,
                policy analysis and briefs, the development of business models,
                business advisory services, etc., all aimed at improving access
                to key information and enabling informed advocacy. The centre
                also aims to bridge the existing research and innovation gaps in
                Rwanda's private sector. It started operations in 2022.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Introduction;
