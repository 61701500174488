import React from "react";
import Wrapper from "../components/Wrapper";
import { aboutPic } from "../assets";
import Introduction from "../sections/Introduction";
import Mission from "../sections/Mission";
import Vision from "../sections/Vision";
import OurClients from "../sections/OurClients";
import HowWeWork from "../sections/HowWeWork";
import ContactFloatButton from "../components/ContactFloatButton";

const AboutContainer = () => {
  return (
    <main>
      <Wrapper
        title="About Business Research Centre"
        description="Strengthening evidence-based research and well-informed advocacy"
        image={aboutPic}
        alt="Wrapper - Business Research"
      />
      <Introduction />
      <Mission />
      <Vision />
      <HowWeWork />
      <OurClients />
      <ContactFloatButton />
    </main>
  );
};

export default AboutContainer;
