import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

const Focus = ({ t }) => {
  return (
    <section
      id="section-number-2"
      className="section-number-2 s-content-columns "
      data-aos="fade-in"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-delay="100">
      <div className="container">
        <div className="row intro">
          <div className="col-12">
            <header className="c-header s-content-columns__header">
              <h2 className="c-heading c-header__heading c-heading--h1 ">
                {t("homePage.whatWeDo.title")}
              </h2>
            </header>
          </div>
        </div>
        <div className="row cards">
          <div className="col-12 col-md-6 col-lg-4 three s-content-columns__card">
            <header className="c-header s-content-columns__card-header">
              <h3 className="c-heading c-header__heading c-heading--h2 s-content-columns__card-heading">
                Data Collection
              </h3>
            </header>
            <div className="c-content">
              <p className="text-justify">
                BRC excels in data collection with a skilled, trustworthy team.
                We ensure accurate information through rigorous training and
                tailored methods.
              </p>
              <button className="s-page-header__buttons">
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className="c-button c-button--link-with-icon s-page-header__button-1"
                  to="/data-collection">
                  <div className="flex items-center">
                    <span className="c-button__label">Read More</span>
                    <div className="c-image c-button__icon ">
                      <MdKeyboardArrowRight size={20} />
                    </div>
                  </div>
                </Link>
              </button>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 three s-content-columns__card">
            <header className="c-header s-content-columns__card-header">
              <h3 className="c-heading c-header__heading c-heading--h2 s-content-columns__card-heading">
                Business Research
              </h3>
            </header>

            <div className="c-content">
              <p className="text-justify">
                BRC's exceptional research capabilities drive success through
                data-driven insights and informed advocacy, establishing a deep
                understanding of the subject matter.
              </p>
              <button className="s-page-header__buttons">
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className="c-button c-button--link-with-icon s-page-header__button-1"
                  to="/business-research">
                  <div className="flex items-center">
                    <span className="c-button__label">Read More</span>
                    <div className="c-image c-button__icon ">
                      <MdKeyboardArrowRight size={20} />
                    </div>
                  </div>
                </Link>
              </button>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 three s-content-columns__card">
            <header className="c-header s-content-columns__card-header">
              <h3 className="c-heading c-header__heading c-heading--h2 s-content-columns__card-heading">
                Analytics
              </h3>
            </header>

            <div className="c-content">
              <p className="text-justify">
                BRC delivers actionable insights from complex data, driving your
                business success through advanced analytics. Trust us for
                informed decision-making.
              </p>
              <button className="s-page-header__buttons">
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className="c-button c-button--link-with-icon s-page-header__button-1"
                  to="/business-analytics">
                  <div className="flex items-center">
                    <span className="c-button__label">Read More</span>
                    <div className="c-image c-button__icon ">
                      <MdKeyboardArrowRight size={20} />
                    </div>
                  </div>
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Focus;
