import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaTrash } from "react-icons/fa";
import { CgSpinner } from "react-icons/cg";
import { RiErrorWarningLine } from "react-icons/ri";
import DefaultModal from "../../../../components/DefaultModal";
import TeamMember from "../../../../modalContents/TeamMember";
import ConfirmModal from "../../../../modalContents/ConfirmModal";
import {
  deleteTeamMember,
  resetState,
} from "../../../../states/features/team/teamSlice";

const Actions = ({ value: id }) => {
  // local state
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  // redux states
  const { isDeleting, isDeleted } = useSelector((state) => state.team);

  // dispatch
  const dispatch = useDispatch();

  // handleCancel function
  const handleCancel = () => {
    setOpenModal((prev) => !prev);
  };

  // handleOk function
  const handleOk = () => {
    dispatch(deleteTeamMember(id));
  };

  useEffect(() => {
    if (isDeleted) {
      setOpenModal(false);
      dispatch(resetState());
    }
  }, [dispatch, isDeleted]);

  // icon component
  const icon = <RiErrorWarningLine size={20} className="text-red-400" />;

  // cancel button component
  const canceButton = (
    <div
      className="border-gray-400 border-2 px-3 rounded text-gray-500 py-[1px] cursor-pointer text-[15px]"
      onClick={handleCancel}
    >
      Cancel
    </div>
  );

  // ok button component
  const okButton = (
    <div
      className="border-red-200 border-2 rounded py-[1px] px-3 overflow-hidden  text-red-500 cursor-pointer text-[15px]"
      onClick={handleOk}
    >
      {isDeleting ? (
        <div className="flex gap-1 items-center">
          <CgSpinner className="animate-spin" />
          <span>Deleting</span>
        </div>
      ) : (
        "Yes"
      )}
    </div>
  );

  return (
    <>
      <button className="flex items-center space-x-4">
        <FaTrash
          size={14}
          className="text-red-800"
          onClick={() => setOpenModal(true)}
        />
        <FaEdit size={16} onClick={() => setOpenModal2(true)} />
      </button>
      {openModal && (
        <ConfirmModal
          text="Are you sure you want to delete this item?"
          icon={icon}
          cancelButton={canceButton}
          okButton={okButton}
        />
      )}
      <DefaultModal
        modalTitle="Update Team Member"
        open={openModal2}
        width={450}
        setOpen={setOpenModal2}
        modalContent={<TeamMember setOpen={setOpenModal2} id={id} />}
      />
    </>
  );
};

export default Actions;
