import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Analytics from "./Analytics";
import Teams from "./Team";

import CompanySettings from "./CompanySettings";
import { getSettings } from "../../../states/features/settings/settingsSlice";
import Units from "./Units";
import Vacancies from "./Vacancies";
import Clients from "./Clients";
import Categories from "./Categories";
import Publications from "./publications/Publications";
import Blogs from "./blogs/Blogs";
import Requests from "./Requests";

const Body = ({ index }) => {
  // dispatch
  const dispatch = useDispatch();

  // redux states
  const { settings, isLoading, isUpdating } = useSelector(
    (state) => state.settings
  );

  // useEffect
  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  return (
    <>
      {index === 1 && <Analytics />}
      {index === 2 && <Teams />}
      {index === 3 && <Units />}
      {index === 4 && <Categories />}
      {index === 5 && <Publications />}
      {index === 6 && <Blogs />}
      {index === 7 && <Clients />}
      {index === 8 && <Vacancies />}
      {index === 9 && <Requests />}
      {index === 10 && (
        <>
          {settings?.data && (
            <CompanySettings
              settings={settings}
              isLoading={isLoading}
              isUpdating={isUpdating}
            />
          )}
        </>
      )}
    </>
  );
};

export default Body;
