import React from "react";
import Wrapper from "../components/Wrapper";
import { publicationWrapper } from "../assets";
import PublicationsCategories from "../sections/PublicationsCategories";
import ContactFloatButton from "../components/ContactFloatButton";

const PublicationCategoriesContainer = () => {
  return (
    <main>
      <Wrapper
        title="Publication Categories"
        description="BRC's publications library, including key reports, policy briefs and papers showcasing our research"
        image={publicationWrapper}
        alt="Wrapper - Publication Categories"
      />
      <PublicationsCategories />
      <ContactFloatButton />
    </main>
  );
};

export default PublicationCategoriesContainer;
