import { pdfSvg } from "../../../../assets";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import DateFormater from "../../../../helpers/DateFormater";
import truncateString from "../../../../utils/truncateString";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePublication,
  resetState,
} from "../../../../states/features/publications/publicationsSlice";
import { Tag, Tooltip } from "antd";
import DefaultModal from "../../../../components/DefaultModal";
import { useState } from "react";
import Publication from "../../../../modalContents/Publication";

const PublicationCard = ({ title, author, category, date, id }) => {
  // local states
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const { isDeleted } = useSelector((state) => state.publications);

  const onDelete = () => {
    dispatch(deletePublication(id));
    if (isDeleted) {
      dispatch(resetState());
    }
  };

  return (
    <div className="flex flex-col md:flex-row items-center border px-3 py-3 rounded bg-white">
      <div className="w-full md:w-3/5 flex space-x-2 items-center mb-2 md:mb-0">
        <img src={pdfSvg} alt="svg" className="w-6 h-6" />
        <Tooltip title={title} color="black" style={{ color: "black" }}>
          <p className="text-[16px] font-medium no-underline text-blue-950 hover:text-blue-900">
            {truncateString(title, 25)}.pdf
          </p>
        </Tooltip>
      </div>
      <div className="w-full md:w-2/5 mb-2 md:mb-0">
        <span className="text-[16px] font-medium">By: {author}</span>
      </div>
      <div className="w-full md:w-2/5 mb-2 md:mb-0">
        <Tag color="volcano">{category}</Tag>
      </div>
      <div className="w-full md:w-1/5">
        <span className="text-[15px] text-gray-600">
          {<DateFormater value={date} />}
        </span>
      </div>
      <div className="w-full md:w-1/5 flex justify-end">
        <Tooltip title="Edit Publication">
          <FiEdit
            className="text-blue-500 hover:text-blue-600 cursor-pointer"
            size={16}
            onClick={() => setOpen(true)}
          />
        </Tooltip>
        <Tooltip title="Delete" color="#ff0000">
          <FiTrash2
            className="text-red-500 hover:text-red-600 cursor-pointer ml-2"
            size={16}
            onClick={onDelete}
          />
        </Tooltip>
      </div>
      <DefaultModal
        modalTitle="Update Publication"
        open={open}
        width={450}
        setOpen={setOpen}
        modalContent={<Publication setOpen={setOpen}/>}
      />
    </div>
  );
};

export default PublicationCard;
