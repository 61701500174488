import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { Empty, Tooltip } from "antd";

import DateFormater from "../../../helpers/DateFormater";
import Table from "../../../components/Table";
import { getUserDownloadHistory } from "../../../states/features/userDownloadHistory/userDownloadHistorySlice";
import Spinner from "../../../components/Spinner";
import NoteTag from "../../../components/NoteTag copy";
import truncateString from "../../../utils/truncateString";

const Requests = () => {
  // dispatch
  const dispatch = useDispatch();

  // redux states
  const { userDownloadHistory, isFetchingAll } = useSelector(
    (state) => state.userDownloadHistory
  );

  console.log(userDownloadHistory);

  // useEffect
  useEffect(() => {
    dispatch(getUserDownloadHistory());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "Fullname",
        accessor: "fullname",
        Cell: ({ cell }) => (
          <span
            style={{
              fontFamily: "GT-Walsheim-Regular",
              fontSize: "15px",
              color: "#374151",
            }}>
            {cell.value}
          </span>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ cell }) => (
          <span
            style={{
              fontFamily: "GT-Walsheim-Regular",
              fontSize: "15px",
              color: "#374151",
            }}>
            {cell.value}
          </span>
        ),
      },
      {
        Header: "Requested Publication title",
        accessor: "link",
        Cell: ({ cell }) => (
          <Tooltip title={cell.value}>
            <span
              style={{
                fontFamily: "GT-Walsheim-Regular",
                fontSize: "15px",
                color: "#374151",
              }}>
              {truncateString(cell.value, 50)}
            </span>
          </Tooltip>
        ),
      },
      {
        Header: "Requested on",
        accessor: "createdAt",
        Cell: DateFormater,
      },
    ],
    []
  );

  // Store data in the memo
  const data = useMemo(
    () => userDownloadHistory?.data || [],
    [userDownloadHistory]
  );

  return (
    <>
      <div className="px-8 pt-12 md:pt-0">
        <div className="flex items-center justify-between">
          <h3 className="font-bold text-md mt-2">All Document Requests</h3>
        </div>
        <NoteTag
          type="info"
          text="Please be aware that these are document requests. You are required to manually send the documents through the BRC email, to provide access to the requestors."
        />
        <div className="min-h-[80vh]">
          {data.length === 0 && isFetchingAll ? (
            <Spinner />
          ) : (
            <>
              {data.length === 0 ? (
                <div className="min-h-[70vh] flex items-center justify-center">
                  <Empty />
                </div>
              ) : (
                <div className="mt-4">
                  <Table columns={columns} data={data} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Requests;
