import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { store } from "./states/store";
import Spinner from "./components/Spinner";

import App from "./App";

import "./langConfig";

import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner moreStyles="min-h-[100vh]" />}>
      <App />
    </Suspense>
  </Provider>,
);
