import React, { useEffect, useMemo } from "react";
import Wrapper from "../components/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import { businessResearchWrapper } from "../assets";
import { getVacancies } from "../states/features/vacancies/vacanciesSlice";
import moment from "moment";
import { Empty, Tag } from "antd";
import ContactFloatButton from "../components/ContactFloatButton";

const VacanciesContainer = () => {
  // dispatch
  const dispatch = useDispatch();

  // staff
  const { vacancies, isFetchingAll } = useSelector((state) => state.vacancies);

  // cache data until changed
  const data = useMemo(() => vacancies?.data || [], [vacancies?.data]);

  // useEffect
  useEffect(() => {
    dispatch(getVacancies());
  }, [dispatch]);

  return (
    <main>
      <Wrapper
        title="Work with BRC"
        description="Join our team and embark on a journey of growth and success."
        image={businessResearchWrapper}
        alt="work with us illustration"
      />

      <div className="container my-10">
        {vacancies.length === 0 && isFetchingAll ? (
          <Spinner moreStyles="min-h-[30vh]" />
        ) : (
          <div className="row news-item">
            <div className="col-12 col-md-8 offset-md-2">
              {data && data.length === 0 ? (
                <div className="mt-[20px] mb-[10vh] flex items-center justify-center w-full">
                  <Empty description="No Job Vacancies yet" />
                </div>
              ) : (
                <div className="grid grid-cols-1 gap-4">
                  {data?.map((vacancy) => {
                    const startDate = moment(vacancy?.createdAt);
                    const endDate = moment(vacancy?.endDate);
                    const currentDate = moment();
                    const isJobOpen = currentDate.isBetween(
                      startDate,
                      endDate,
                      null,
                      "[]",
                    );
                    const tagColor = isJobOpen ? "#f68920" : "#e9290b";
                    const tagText = isJobOpen ? "OPEN" : "CLOSED";

                    return (
                      <div
                        key={vacancy?.id}
                        className="bg-white shadow-sm p-4 rounded-sm flex lg:flex-row flex-col lg:items-center gap-4"
                      >
                        <div className="flex-1">
                          <h2 className="text-lg font-semibold mb-2">
                            {vacancy?.jobTitle}
                          </h2>
                          <p className="text-md text-gray-600 -mb-2 text-justify">
                            {vacancy?.intro}
                          </p>
                          <div className="flex flex-row space-x-1 items-center pt-[15px]">
                            <Tag color={tagColor}>{tagText}</Tag>
                            <p className="text-sm text-gray-400">
                              {startDate.format("MMM Do YYYY")} -{" "}
                              {endDate.format("MMM Do YYYY")}
                            </p>
                          </div>
                        </div>
                        {isJobOpen ? (
                          <a
                            href={vacancy?.link}
                            target="_blank"
                            className="bg-[#f68920] no-underline hover:bg-opacity-95 text-white py-[5px] px-4 rounded text-center"
                            rel="noreferrer"
                          >
                            Apply Now
                          </a>
                        ) : (
                          <div className="bg-gray-300 text-gray-600 py-[5px] px-4 rounded text-center">
                            Job Closed
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <ContactFloatButton />
    </main>
  );
};

export default VacanciesContainer;
