import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { darkLogo } from "../assets";
import { resetState, resetpw } from "../states/features/auth/authSlice";
import { ImSpinner2 } from "react-icons/im";
import { notification } from "../utils";
import ContactFloatButton from "../components/ContactFloatButton";

const ResetPWContainer = () => {
  // translations
  const { t } = useTranslation();

  // react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({ mode: "all" });

  // watch
  const newPassword = watch("password");

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // params
  const { resetToken } = useParams();

  // redux states
  const { isSuccess, isError, isLoading, message } = useSelector(
    (state) => state.auth,
  );

  // handle submission
  const onSubmit = ({ password }) => {
    dispatch(resetpw({ password, resetToken }));
  };

  // useEffect
  useEffect(() => {
    if (isError) {
      notification(message, "error", "bottomLeft");
    }
    if (isSuccess) {
      navigate("/login");
      reset({
        newPassword: "",
        confirmNewPassword: "",
      });
    }
    dispatch(resetState());
  }, [navigate, isSuccess, reset, dispatch, message, isError]);

  return (
    <div className="reset-container">
      <Form className="reset-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="logo-container">
          <Link to="/">
            <img src={darkLogo} alt="logo" loading="lazy" />
          </Link>
          <p>
            {t("resetpwPage.titlePart1")}
            <br /> {t("resetpwPage.titlePart2")}
          </p>
        </div>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>{t("resetpwPage.passwordLabel")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("resetpwPage.passwordPlaceholder")}
            {...register("password", {
              required: true,
            })}
          />
          {errors.password && (
            <span className="text-red-700 text-[15px]">Password required</span>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>{t("resetpwPage.confirmPasswordLabel")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("resetpwPage.confirmPasswordPlaceholder")}
            {...register("confirmPassword", {
              required: true,
              validate: (value) => value === newPassword,
            })}
          />

          {(errors.confirmPassword?.type === "required" && (
            <span className="text-red-700 text-[15px]">
              Confirm your password required
            </span>
          )) ||
            (errors.confirmPassword?.type === "validate" && (
              <span className="text-red-700 text-[15px]">
                Password do not match
              </span>
            ))}
        </Form.Group>

        <Button variant="primary" type="submit" disabled={isLoading}>
          {!isLoading ? (
            t("resetpwPage.buttonText")
          ) : (
            <span className="flex justify-center items-center">
              Sending you a reset link
              <ImSpinner2 size={20} className="animate-spin ml-2" />
            </span>
          )}
        </Button>
        <div className="text-center mt-3">
          <div>
            {" "}
            <span className="text-[16px]">
              {t("resetpwPage.footerPart1")}
            </span>{" "}
            <Link to="/forgot-password">{t("resetpwPage.footerPart2")}</Link>
          </div>
        </div>
      </Form>
      <ContactFloatButton />
    </div>
  );
};

export default ResetPWContainer;
