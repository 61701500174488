import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import { Empty } from "antd";

import { getClients } from "../../../states/features/clients/clientsSlice";
import Spinner from "../../../components/Spinner";
import RoundedBtn from "../../../components/RoundedBtn";
import DefaultModal from "../../../components/DefaultModal";
import Client from "../../../modalContents/Client";
import DateFormater from "../../../helpers/DateFormater";
import Actions from "../actions/clients/Actions";
import Table from "../../../components/Table";

const Clients = () => {
  // URL
  const URL = process.env.REACT_APP_BASE_API_URL;

  // local states
  const [open, setOpen] = useState(false);

  // dispatch
  const dispatch = useDispatch();

  // clients - redux state
  const { clients, isFetchingAll, isCreated, isUpdated, isDeleted } =
    useSelector((state) => state.clients);

  // useEffect
  useEffect(() => {
    dispatch(getClients());
  }, [dispatch, isCreated, isUpdated, isDeleted]);

  const columns = useMemo(
    () => [
      {
        Header: "Client Name",
        accessor: "name",
        Cell: ({ cell }) => (
          <span
            style={{
              fontFamily: "GT-Walsheim-Regular",
              fontSize: "15px",
              color: "#374151",
            }}
          >
            {cell.value}
          </span>
        ),
      },
      {
        Header: "Logo",
        accessor: "image",
        Cell: ({ cell }) => (
          <a
            href={`${URL}/${cell.row.original.image}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontFamily: "GT-Walsheim-Regular",
              fontSize: "15px",
              color: "#074ec2",
            }}
          >
            Open Image link
          </a>
        ),
      },
      {
        Header: "Created On",
        accessor: "createdAt",
        Cell: DateFormater,
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: Actions,
      },
    ],
    [URL],
  );

  // Store data in the memo
  const data = useMemo(() => clients?.data || [], [clients]);

  return (
    <>
      <div className="px-8 pt-12 md:pt-0">
        <div className="flex items-center justify-between">
          <h3 className="font-bold text-md mt-2">All Clients</h3>
          <RoundedBtn
            title="Add Client"
            moreStyles="bg-primary text-white py-2 text-sm"
            onClick={() => setOpen(true)}
          />
        </div>
        <div className="min-h-[80vh]">
          {data.length === 0 && isFetchingAll ? (
            <Spinner />
          ) : (
            <>
              {data.length === 0 ? (
                <div className="min-h-[70vh] flex items-center justify-center">
                  <Empty />
                </div>
              ) : (
                <div className="mt-4">
                  <Table columns={columns} data={data} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <DefaultModal
        modalTitle="Add Client"
        open={open}
        width={450}
        setOpen={setOpen}
        modalContent={<Client setOpen={setOpen} type="new" />}
      />
    </>
  );
};

export default Clients;
