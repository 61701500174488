import React, { useState } from "react";
import { Tooltip, Modal } from "antd";
import { structure } from "../assets";

const HowWeWork = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <section
      id="section-number-5"
      className="section-number-5 s-content-image section--bg-color"
    >
      <div className="container">
        <div className="row ">
          <div className="col-12 col-md-5 s-content-image__image--aligned-center s-content-image__image">
            <Tooltip title="Click the image for a clearer display" placement="right">
              <div className="c-image" onClick={openModal}>
                <img
                  className="attachment-s-content-image__image size-s-content-image__image wp-post-image c-image__img object-cover"
                  alt="Collaboration and learning"
                  src={structure}
                />
              </div>
            </Tooltip>
          </div>
          <div className="col-12 col-md-6 offset-md-1 s-content-image__content s-content-image__content--aligned-center">
            <header className="c-header">
              <h3 className="c-heading c-header__heading c-heading--h2">
                BRC’s Organization Structure
              </h3>
            </header>

            <div className="c-content s-content-image__content">
              <p className="text-justify">
                The Centre is headed by the Executive Director who is assisted
                by 6 units; Research and Analysis Unit, Research Quality
                Assurance Unit, Business related Environment Unit, Outreach,
                Communication and Partnership Unit.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={modalVisible}
        onCancel={closeModal}
        footer={null}
        width={900} 
      >
        <img
          src={structure}
          alt="Collaboration and learning"
          style={{ width: "100%" }}
        />
      </Modal>
    </section>
  );
};

export default HowWeWork;
