import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import blogsServices from "./blogsServices";

const blogSlice = createSlice({
  name: "blogs",
  initialState: {
    blogs: [],
    blog: [],
    isError: false,
    isSuccess: false,
    isFetchingAll: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    message: "",
  },
  reducers: {
    resetState: (state) => {
      state.isFetchingAll = false;
      state.isCreating = false;
      state.isUpdating = false;
      state.isDeleting = false;
      state.isSuccess = false;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDeleted = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlogs.pending, (state) => {
        state.isFetchingAll = true;
      })
      .addCase(getBlogs.fulfilled, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isSuccess = true;
        state.blogs = payload;
      })
      .addCase(getBlogs.rejected, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isError = true;
        state.message = payload;
        state.blogs = [];
      })
      .addCase(getBlog.pending, (state) => {
        state.isFetchingAll = true;
      })
      .addCase(getBlog.fulfilled, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isSuccess = true;
        state.blog = payload;
      })
      .addCase(getBlog.rejected, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isError = true;
        state.message = payload;
        state.blog = [];
      })
      .addCase(addBlog.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(addBlog.fulfilled, (state, { payload }) => {
        state.isCreating = false;
        state.isSuccess = true;
        state.isCreated = true;
        state.message = payload;
      })
      .addCase(addBlog.rejected, (state, { payload }) => {
        state.isCreating = false;
        state.isError = true;
        state.message = payload;
        state.blogs = [];
      })
      .addCase(updateBlog.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateBlog.fulfilled, (state, { payload }) => {
        state.isUpdating = false;
        state.isSuccess = true;
        state.isUpdated = true;
        state.message = payload;
      })
      .addCase(updateBlog.rejected, (state, { payload }) => {
        state.isUpdating = false;
        state.isError = true;
        state.message = payload;
        state.blogs = [];
      });
  },
});

// Get all blogs
export const getBlogs = createAsyncThunk("get/blogs", async (thunkAPI) => {
  try {
    return await blogsServices.getBlogs();
  } catch (error) {
    const message =
      error.response.data.errors ||
      error.response.data.status ||
      error.response.data.message;
    error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Get all blog
export const getBlog = createAsyncThunk("get/blog", async (id, thunkAPI) => {
  try {
    return await blogsServices.getBlog(id);
  } catch (error) {
    const message =
      error.response.data.errors ||
      error.response.data.status ||
      error.response.data.message;
    error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add a Blog
export const addBlog = createAsyncThunk("add/blog", async (data, thunkAPI) => {
  try {
    return await blogsServices.addBlog(data);
  } catch (error) {
    console.log(error);
    const message =
      error.response.data.errors ||
      error.response.data.status ||
      error.response.data.message;
    error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Update a Blog
export const updateBlog = createAsyncThunk(
  "update/blog",
  async (data, thunkAPI) => {
    try {
      return await blogsServices.updateBlog(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Delete a Blog
export const deleteBlog = createAsyncThunk(
  "delete/blog",
  async (data, thunkAPI) => {
    try {
      return await blogsServices.deleteBlog(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const { resetState } = blogSlice.actions;

export default blogSlice.reducer;
