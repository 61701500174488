import API from "../../../api/api";
import { unitEndpoint } from "../../../constants";
import { notification } from "../../../utils";

// Get all units
const getUnits = async () => {
  const response = await API.get(unitEndpoint);
  return response.data;
};

// Add a units
const addUnit = async (data) => {
  const response = await API.post(unitEndpoint, data);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// Update a unit
const updateUnit = async (data) => {
  const response = await API.put(unitEndpoint, data);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// Add a unit
const deleteUnit = async (id) => {
  const response = await API.delete(`${unitEndpoint}/${id}`);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

const unitsServices = {
  getUnits,
  addUnit,
  updateUnit,
  deleteUnit,
};

export default unitsServices;
