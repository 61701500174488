import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categoriesServices from "./categoriesServices";

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    categories: [],
    category: [],
    isError: false,
    isSuccess: false,
    isFetchingAll: false,
    isFetchingOne: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    message: "",
  },
  reducers: {
    resetState: (state) => {
      state.isFetchingAll = false;
      state.isFetchingOne = false;
      state.isCreating = false;
      state.isUpdating = false;
      state.isDeleting = false;
      state.isSuccess = false;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDeleted = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.isFetchingAll = true;
      })
      .addCase(getCategories.fulfilled, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isSuccess = true;
        state.categories = payload;
      })
      .addCase(getCategories.rejected, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isError = true;
        state.message = payload;
        state.categories = [];
      })
      .addCase(getCategory.pending, (state) => {
        state.isFetchingOne = true;
      })
      .addCase(getCategory.fulfilled, (state, { payload }) => {
        state.isFetchingOne = false;
        state.isSuccess = true;
        state.category = payload;
      })
      .addCase(getCategory.rejected, (state, { payload }) => {
        state.isFetchingOne = false;
        state.isError = true;
        state.message = payload;
        state.category = [];
      })
      .addCase(addCategory.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(addCategory.fulfilled, (state, { payload }) => {
        state.isCreating = false;
        state.isSuccess = true;
        state.isCreated = true;
        state.message = payload;
      })
      .addCase(addCategory.rejected, (state, { payload }) => {
        state.isCreating = false;
        state.isError = true;
        state.message = payload;
        state.categories = [];
      })
      .addCase(updateCategory.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateCategory.fulfilled, (state, { payload }) => {
        state.isUpdating = false;
        state.isSuccess = true;
        state.isUpdated = true;
        state.message = payload;
      })
      .addCase(updateCategory.rejected, (state, { payload }) => {
        state.isUpdating = false;
        state.isError = true;
        state.message = payload;
        state.categories = [];
      })
      .addCase(deleteCategory.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(deleteCategory.fulfilled, (state, { payload }) => {
        state.isDeleting = false;
        state.isSuccess = true;
        state.isDeleted = true;
        state.message = payload;
      })
      .addCase(deleteCategory.rejected, (state, { payload }) => {
        state.isDeleting = false;
        state.isError = true;
        state.message = payload;
        state.categories = [];
      });
  },
});

// Get all categories
export const getCategories = createAsyncThunk(
  "get/categories",
  async (thunkAPI) => {
    try {
      return await categoriesServices.getCategories();
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Get one category
export const getCategory = createAsyncThunk(
  "get/category",
  async (id, thunkAPI) => {
    try {
      return await categoriesServices.getCategory(id);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Add category
export const addCategory = createAsyncThunk(
  "add/category",
  async (data, thunkAPI) => {
    try {
      return await categoriesServices.addCategory(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Update a category
export const updateCategory = createAsyncThunk(
  "update/category",
  async (data, thunkAPI) => {
    try {
      return await categoriesServices.updateCategory(data);
    } catch (error) {
      console.log(error);
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Delete a category
export const deleteCategory = createAsyncThunk(
  "delete/category",
  async (id, thunkAPI) => {
    try {
      return await categoriesServices.deleteCategory(id);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const { resetState } = categoriesSlice.actions;

export default categoriesSlice.reducer;
