import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import clientsServices from "./clientsServices";

const clientsSlice = createSlice({
  name: "clients",
  initialState: {
    clients: [],
    isError: false,
    isSuccess: false,
    isFetchingAll: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    message: "",
  },
  reducers: {
    resetState: (state) => {
      state.isFetchingAll = false;
      state.isCreating = false;
      state.isUpdating = false;
      state.isDeleting = false;
      state.isSuccess = false;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDeleted = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClients.pending, (state) => {
        state.isFetchingAll = true;
      })
      .addCase(getClients.fulfilled, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isSuccess = true;
        state.clients = payload;
      })
      .addCase(getClients.rejected, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isError = true;
        state.message = payload;
        state.clients = [];
      })
      .addCase(addClient.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(addClient.fulfilled, (state, { payload }) => {
        state.isCreating = false;
        state.isSuccess = true;
        state.isCreated = true;
        state.message = payload;
      })
      .addCase(addClient.rejected, (state, { payload }) => {
        state.isCreating = false;
        state.isError = true;
        state.message = payload;
        state.clients = [];
      })
      .addCase(updateClient.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateClient.fulfilled, (state, { payload }) => {
        state.isUpdating = false;
        state.isSuccess = true;
        state.isUpdated = true;
        state.message = payload;
      })
      .addCase(updateClient.rejected, (state, { payload }) => {
        state.isUpdating = false;
        state.isError = true;
        state.message = payload;
        state.clients = [];
      })
      .addCase(deleteClient.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(deleteClient.fulfilled, (state, { payload }) => {
        state.isDeleting = false;
        state.isSuccess = true;
        state.isDeleted = true;
        state.message = payload;
      })
      .addCase(deleteClient.rejected, (state, { payload }) => {
        state.isDeleting = false;
        state.isError = true;
        state.message = payload;
        state.clients = [];
      });
  },
});

// Get all clients
export const getClients = createAsyncThunk("get/clients", async (thunkAPI) => {
  try {
    return await clientsServices.getClients();
  } catch (error) {
    const message =
      error.response.data.errors ||
      error.response.data.status ||
      error.response.data.message;
    error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add client
export const addClient = createAsyncThunk(
  "add/client",
  async (data, thunkAPI) => {
    try {
      return await clientsServices.addClient(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Update client
export const updateClient = createAsyncThunk(
  "update/client",
  async (data, thunkAPI) => {
    try {
      return await clientsServices.updateClient(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Delete client
export const deleteClient = createAsyncThunk(
  "delete/client",
  async (id, thunkAPI) => {
    try {
      return await clientsServices.deleteClient(id);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const { resetState } = clientsSlice.actions;

export default clientsSlice.reducer;
