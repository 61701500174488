import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DatePicker } from "antd";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import {
  addVacancy,
  resetState,
} from "../states/features/vacancies/vacanciesSlice";
import PrimaryBtn from "../components/PrimaryBtn";

const Vacancy = ({ setOpen }) => {
  // local states and react hook form
  const { register, handleSubmit, formState } = useForm({ mode: "all" });
  const { errors } = formState;
  const [endDate, setEndDate] = useState(moment(moment().add(20, "days")));

  const { isCreating, isCreated } = useSelector((state) => state.vacancies);

  const dispatch = useDispatch();

  const onSubmitForm = ({ jobTitle, intro, link }) => {
    const data = {
      jobTitle,
      intro,
      link,
      endDate,
    };
    dispatch(addVacancy(data));
  };

  useEffect(() => {
    if (isCreated) {
      setOpen(false);
      dispatch(resetState());
    }
  }, [dispatch, isCreated, setOpen]);

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmitForm)}>
      <div className="flex flex-col">
        <label className="text-[14px]">Job Title</label>
        <input
          className="text-input w-full"
          type="text"
          name="jobTitle"
          {...register("jobTitle", { required: true })}
        />
        <span className="text-red-600 text-[13px] mb-2 -mt-2">
          {errors.jobTitle && "Job Title is required"}
        </span>
      </div>

      <div className="flex flex-col">
        <label className="text-[14px]">Job Introduction</label>
        <textarea
          className="text-input w-full"
          type="text"
          name="intro"
          {...register("intro", { required: true })}
        />
        <span className="text-red-600 text-[13px] mb-2 -mt-2">
          {errors.intro && "Job Introduction is required"}
        </span>
      </div>

      <div className="flex flex-col">
        <label className="text-[14px]">Job Link</label>
        <input
          className="text-input w-full"
          type="text"
          name="link"
          {...register("link", {
            required: "Job Link is required",
            pattern: {
              value: /^(ftp|http|https):\/\/[^ "]+$/,
              message: "Invalid URL format",
            },
          })}
        />
        <span className="text-red-600 text-[13px] mb-2 -mt-2">
          {errors.link && errors.link.message}
        </span>
      </div>

      <div className="flex flex-col">
        <label className="text-[14px]">Job Post Expiry Date</label>
        <DatePicker onChange={(_, date) => setEndDate(moment(date))} />
        <span className="text-red-600 text-[13px] mb-2">
          {endDate === moment() && "End Date is required"}
        </span>
      </div>

      <div className="mt-2">
        <PrimaryBtn
          type="submit"
          moreStyles="bg-primary ml-auto"
          title={"Add Job Vacancy"}
          isLoading={isCreating}
          loadingTitle="Adding Job Vacancy"
        />
      </div>
    </form>
  );
};

export default Vacancy;
