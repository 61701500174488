import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import unitsServices from "./unitsServices";

const unitsSlice = createSlice({
  name: "units",
  initialState: {
    units: [],
    isError: false,
    isSuccess: false,
    isFetchingAll: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    message: "",
  },
  reducers: {
    resetState: (state) => {
      state.isFetchingAll = false;
      state.isCreating = false;
      state.isUpdating = false;
      state.isDeleting = false;
      state.isSuccess = false;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDeleted = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnits.pending, (state) => {
        state.isFetchingAll = true;
      })
      .addCase(getUnits.fulfilled, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isSuccess = true;
        state.units = payload;
      })
      .addCase(getUnits.rejected, (state, { payload }) => {
        state.isFetchingAll = false;
        state.isError = true;
        state.message = payload;
        state.units = [];
      })
      .addCase(addUnit.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(addUnit.fulfilled, (state, { payload }) => {
        state.isCreating = false;
        state.isSuccess = true;
        state.isCreated = true;
        state.message = payload;
      })
      .addCase(addUnit.rejected, (state, { payload }) => {
        state.isCreating = false;
        state.isError = true;
        state.message = payload;
        state.units = [];
      })
      .addCase(updateUnit.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateUnit.fulfilled, (state, { payload }) => {
        state.isUpdating = false;
        state.isSuccess = true;
        state.isUpdated = true;
        state.message = payload;
      })
      .addCase(updateUnit.rejected, (state, { payload }) => {
        state.isUpdating = false;
        state.isError = true;
        state.message = payload;
        state.units = [];
      })
      .addCase(deleteUnit.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(deleteUnit.fulfilled, (state, { payload }) => {
        state.isDeleting = false;
        state.isSuccess = true;
        state.isDeleted = true;
        state.message = payload;
      })
      .addCase(deleteUnit.rejected, (state, { payload }) => {
        state.isDeleting = false;
        state.isError = true;
        state.message = payload;
        state.units = [];
      });
  },
});

// Get all units
export const getUnits = createAsyncThunk("get/units", async (thunkAPI) => {
  try {
    return await unitsServices.getUnits();
  } catch (error) {
    const message =
      error.response.data.errors ||
      error.response.data.status ||
      error.response.data.message;
    error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add unit
export const addUnit = createAsyncThunk("add/unit", async (data, thunkAPI) => {
  try {
    return await unitsServices.addUnit(data);
  } catch (error) {
    const message =
      error.response.data.errors ||
      error.response.data.status ||
      error.response.data.message;
    error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Update unit
export const updateUnit = createAsyncThunk(
  "update/unit",
  async (data, thunkAPI) => {
    try {
      return await unitsServices.updateUnit(data);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Delete unit
export const deleteUnit = createAsyncThunk(
  "delete/unit",
  async (id, thunkAPI) => {
    try {
      return await unitsServices.deleteUnit(id);
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const { resetState } = unitsSlice.actions;

export default unitsSlice.reducer;
