import { Link, NavLink } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { MdDashboard, MdSwitchAccount } from "react-icons/md";

const MobileSideBar = ({
  open,
  setOpen,
  changeLanguage,
  language,
  t,
  role,
}) => {
  return (
    <div
      className={`${
        open ? "top-0 right-0 fixed bg-black/40 w-[100%] z-50 h-screen" : ""
      }`}
    >
      <div
        className={`fixed w-[70%] top-0 bottom-0 right-0 bg-gray-100 h-full ml-auto transition-all ease-linear duration-300 z-50 ${
          open
            ? "translate-x-0 transition-all ease-linear duration-300"
            : "translate-x-full"
        } `}
      >
        <div className="flex justify-center m-2">
          <div className=" p-3 bg-white w-fit rounded-full ">
            <AiOutlineClose
              size={30}
              className=""
              onClick={() => setOpen((prev) => !prev)}
            />
          </div>
        </div>

        <ul id="menu-primary-menu-en" className="nav__menu nav__menu--primary">
          <li className="nav__menu-item nav__menu-item--top-level">
            <NavLink
              className="nav-link nav__menu-link nav__menu-link--top-level"
              to="about-us"
              onClick={() => window.scrollTo(0, 0)}
            >
              {t("navbar.about")}
            </NavLink>
          </li>
          <li className="nav__menu-item nav__menu-item--top-level nav__menu-item--has-children">
            <NavLink className="nav-link nav__menu-link nav__menu-link--top-level">
              {t("navbar.whatWeDo.title")}
            </NavLink>
            <ul className="nav__sub-menu sub-menu">
              <li className="nav__sub-menu-item">
                <NavLink
                  className="nav-link nav__sub-menu-link"
                  to="data-collection"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("navbar.whatWeDo.subItem1")}
                </NavLink>
              </li>
              <li className="nav__sub-menu-item">
                <NavLink
                  className="nav-link nav__sub-menu-link"
                  to="business-research"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("navbar.whatWeDo.subItem2")}
                </NavLink>
              </li>
              <li className="nav__sub-menu-item">
                <NavLink
                  className="nav-link nav__sub-menu-link"
                  to="business-analytics"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("navbar.whatWeDo.subItem3")}
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav__menu-item nav__menu-item--top-level">
            <NavLink
              className="nav-link nav__menu-link nav__menu-link--top-level"
              to="publications"
              onClick={() => window.scrollTo(0, 0)}
            >
              {t("navbar.publications")}
            </NavLink>
          </li>

          <li className="nav__menu-item nav__menu-item--top-level">
            <NavLink
              className="nav-link nav__menu-link nav__menu-link--top-level"
              to="blogs"
              onClick={() => window.scrollTo(0, 0)}
            >
              {t("navbar.blog")}
            </NavLink>
          </li>
          <li className="nav__menu-item nav__menu-item--top-level nav__menu-item--has-children">
            <NavLink className="nav-link nav__menu-link nav__menu-link--top-level">
              {t("navbar.ourTeam.title")}
            </NavLink>
            <ul className="nav__sub-menu sub-menu">
              <li className="nav__sub-menu-item">
                <NavLink
                  className="nav-link nav__sub-menu-link"
                  to="board-of-directors"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("navbar.ourTeam.subItem0")}
                </NavLink>
              </li>
              <li className="nav__sub-menu-item">
                <NavLink
                  className="nav-link nav__sub-menu-link"
                  to="brc-team"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("navbar.ourTeam.subItem1")}
                </NavLink>
              </li>
              <li className="nav__sub-menu-item">
                <NavLink
                  className="nav-link nav__sub-menu-link"
                  to="team-of-fellows"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("navbar.ourTeam.subItem2")}
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav__menu-item nav__menu-item--top-level nav__menu-item--has-children">
            <NavLink className="nav-link nav__menu-link nav__menu-link--top-level">
              {language}
            </NavLink>
            <ul className="nav__sub-menu sub-menu">
              <li className="nav__sub-menu-item">
                <NavLink
                  className="nav-link nav__sub-menu-link"
                  onClick={() => changeLanguage("en")}
                >
                  {t("navbar.languages.english")}
                </NavLink>
              </li>
              <li className="nav__sub-menu-item">
                <NavLink
                  className="nav-link nav__sub-menu-link"
                  onClick={() => changeLanguage("fr")}
                >
                  {t("navbar.languages.french")}
                </NavLink>
              </li>
              <li className="nav__sub-menu-item">
                <NavLink
                  className="nav-link nav__sub-menu-link"
                  onClick={() => changeLanguage("rw")}
                >
                  {t("navbar.languages.kinyarwanda")}
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="nav__menu-item nav__menu-item--top-level">
            {role === "admin" ? (
              <NavLink
                className="nav-link nav__menu-link nav__menu-link--top-level flex justify-center "
                to="dashboard"
                onClick={() => window.scrollTo(0, 0)}
              >
                <MdDashboard size="30" className="text-primary text-center" />
              </NavLink>
            ) : (
              <NavLink
                className="nav-link nav__menu-link nav__menu-link--top-level flex justify-center"
                to="login"
                onClick={() => window.scrollTo(0, 0)}
              >
                <MdSwitchAccount size="30" className="text-primary" />
              </NavLink>
            )}
          </li>
        </ul>
        <ul
          id="menu-buttons-menu-en"
          className="nav__menu nav__menu--buttons c-buttons-menu"
        >
          <li className="nav__menu-item nav__menu-item--top-level">
            <NavLink
              className="nav-link nav__menu-link nav__menu-link--top-level"
              to="contact-us"
              onClick={() => window.scrollTo(0, 0)}
            >
              {t("navbar.contact")}
            </NavLink>
          </li>
        </ul>

        <div className="mt-[10em] p-6">
          <Link
            to="/login"
            className="bg-primary px-6 pt-[7px] pb-[8px] text-white link rounded-md"
          >
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileSideBar;
