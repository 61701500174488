import ContactFloatButton from "../components/ContactFloatButton";
import BoDirectors from "../sections/BoDirectors";

const BrcBoDContainer = () => {
  return (
    <main>
      <BoDirectors />
      <ContactFloatButton />
    </main>
  );
};

export default BrcBoDContainer;
