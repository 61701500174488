import API from "../../../api/api";
import { vacanciesEndpoint } from "../../../constants";
import { notification } from "../../../utils";

// Get all Vacancies
const getVacancies = async () => {
  const response = await API.get(vacanciesEndpoint);
  return response.data;
};

// Add a Vacancy
const addVacancy = async (data) => {
  const response = await API.post(vacanciesEndpoint, data);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// Update a Vacancy
const updateVacancy = async (data) => {
  const response = await API.put(vacanciesEndpoint, data);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// Delete a Vacancy
const deleteVacancy = async (id) => {
  const response = await API.delete(`${vacanciesEndpoint}/${id}`);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

const vacanciesServices = {
  getVacancies,
  addVacancy,
  updateVacancy,
  deleteVacancy,
};

export default vacanciesServices;
