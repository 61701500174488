import React from "react";
import Wrapper from "../components/Wrapper";
import { privacy } from "../assets";
import { Link } from "react-router-dom";
import ContactFloatButton from "../components/ContactFloatButton";

const PrivacyPolicyContainer = () => {
  return (
    <main>
      {/* Page Header */}
      <Wrapper
        title="Privacy Guidelines"
        description="Learn about our Privacy Guidelines"
        image={privacy}
        alt="Privacy Guidelines"
      />

      <div className="container my-10">
        <div className="row news-item">
          <div className="col-12 col-md-8 offset-md-2">
            <h1 className="text-3xl font-semibold mb-6">Privacy Guidelines</h1>

            {/* Section 1: Introduction */}
            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
              <p className="text-gray-700 text-justify">
                Welcome to the Business Research Centre (BRC). We are committed
                to safeguarding the privacy and security of your personal
                information and ensuring a positive experience when you use our
                website and services. This Privacy Guidelines document outlines
                how we collect, use, disclose, and protect your information and
                the terms and conditions governing your use of our website.
              </p>
            </div>

            {/* Section 2: Information We Collect */}
            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">
                2. Information We Collect
              </h2>
              <p className="text-gray-700 text-justify">
                We may collect personal information such as your name, email
                address, and contact details when you voluntarily provide it to
                us, such as when you subscribe to our newsletter or submit a
                contact form.
              </p>
            </div>

            {/* Section 3: Use of Information */}
            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">
                3. Use of Information
              </h2>
              <p className="text-gray-700 text-justify">
                We use your personal information to provide you with the
                services you request, such as sending newsletters or responding
                to inquiries.
              </p>
            </div>

            {/* Section 4: Disclosure of Information */}
            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">
                4. Disclosure of Information
              </h2>
              <p className="text-gray-700 text-justify">
                We do not sell or rent your personal information to third
                parties.
              </p>
            </div>

            {/* Section 5: Intellectual Property */}
            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">
                5. Intellectual Property
              </h2>
              <p className="text-gray-700 text-justify">
                5.1. All content on the BRC website, including text, graphics,
                logos, and research publications, is protected by intellectual
                property rights and is the property of BRC or its licensors.{" "}
                <br />
                5.2. You may use the content for personal, non-commercial
                purposes. Any other use, including reproduction, distribution,
                or modification, without our prior written consent, is
                prohibited.
              </p>
            </div>

            {/* Section 6: Disclaimer */}
            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">6. Disclaimer</h2>
              <p className="text-gray-700 text-justify">
                BRC is not responsible for any decisions made based on the
                information presented on our website.
              </p>
            </div>

            {/* Section 7: External Links */}
            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">7. External Links</h2>
              <p className="text-gray-700 text-justify">
                Our website may contain links to external websites. BRC is not
                responsible for the content or practices of these external
                sites.
              </p>
            </div>

            {/* Section 8: Contact Us */}
            <div className="mb-2">
              <h2 className="text-2xl font-semibold mb-4">8. Contact Us</h2>
              <div className="text-gray-700 text-justify inline-block">
                If you have any questions or concerns about these Privacy
                Guidelines, please{" "}
                <Link className="inline-flex" to="/contact-us">
                  contact us here
                </Link>
                .
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactFloatButton />
    </main>
  );
};

export default PrivacyPolicyContainer;
