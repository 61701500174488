import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import analyticsServices from "./analyticsService";

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    analytics: [],
    isError: false,
    isSuccess: false,
    isFetching: false,
    message: "",
  },
  reducers: {
    resetState: (state) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnalytics.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAnalytics.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.analytics = payload;
      })
      .addCase(getAnalytics.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.message = payload;
        state.analytics = [];
      });
  },
});

// Get analytics
export const getAnalytics = createAsyncThunk(
  "get/analytics",
  async (thunkAPI) => {
    try {
      return await analyticsServices.getAnalytics();
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const { resetState } = analyticsSlice.actions;

export default analyticsSlice.reducer;
