import React from "react";
import { useDispatch } from "react-redux";

import { logout } from "../states/features/auth/authSlice";
import { lsUser } from "../constants";
import { parseJwt } from "../utils";

const VerifyAuth = () => {
  // Get toten from LS
  const user = JSON.parse(localStorage.getItem(lsUser));
  const token = user?.token;

  // initialize dispatch
  const dispatch = useDispatch();

  // Check token if valid
  if (token) {
    const decodedJwt = parseJwt(token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      dispatch(logout());
    }
  }
  return <></>;
};
export default VerifyAuth;
