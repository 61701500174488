import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaSchool, FaUsers } from "react-icons/fa";
import { getAnalytics } from "../../../states/features/analytics/analyticsSlice";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import Spinner from "../../../components/Spinner";

const Analytics = () => {
  // dispatch
  const dispatch = useDispatch();

  // get analytics
  const { analytics, isFetching } = useSelector((state) => state.analytics);

  // useEffect
  useEffect(() => {
    dispatch(getAnalytics());
  }, [dispatch]);

  const data = useMemo(() => analytics?.data || [], [analytics]);

  return (
    <div className="px-8 pt-12 md:pt-0">
      <div className="flex items-center justify-between">
        <h3 className="font-bold">BRC Analytics</h3>
      </div>
      {data.length === 0 && isFetching ? (
        <div className="flex items-center justify-center h-[80vh]">
          <Spinner className="my-0" />
        </div>
      ) : (
        <>
          {data && (
            <div className="mt-2 bg-gray-50 md:py-6 rounded-xl" id="analytics">
              <div className="flex flex-wrap lg:flex-nowrap justify-between px-4 ">
                <div className="bg-white  h-44 rounded-xl w-full lg:w-[340px] p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="font-bold text-black">Team Members</p>
                      <p className="text-2xl text-gray-500">
                        {data.teamMembers}
                      </p>
                    </div>
                    <button
                      type="button"
                      style={{ backgroundColor: "var(--primary)" }}
                      className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
                    >
                      <FaUsers />
                    </button>
                  </div>
                  <p className="text-gray-500">Total Team Members</p>
                </div>

                <div className="flex m-3 flex-wrap justify-center space-x-4 items-center">
                  <div className="bg-white h-44 w-full md:w-52  p-8 md:p-4 pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "#03C9D7",
                        backgroundColor: "#E5FAFB",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                    >
                      <MdOutlineSupervisorAccount />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {data.blogs}
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">All Blogs</p>
                  </div>

                  <div className="bg-white h-44 w-full md:w-52  p-8 md:p-4 pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "rgb(255, 244, 229)",
                        backgroundColor: "rgb(254, 201, 15)",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                    >
                      <MdOutlineSupervisorAccount />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {data.publicationCategories}
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">
                      All Publication Categories
                    </p>
                  </div>

                  <div className="bg-white h-44  w-full md:w-52  p-8 md:p-4 pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "rgb(0, 194, 146)",
                        backgroundColor: "rgb(235, 250, 242)",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                    >
                      <MdOutlineSupervisorAccount />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {data.publications}
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">
                      All Publications
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap lg:flex-nowrap justify-between px-4 ">
                <div className="bg-white h-44 rounded-xl w-full lg:w-[340px] p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="font-bold text-black">Subscriptions</p>
                      <p className="text-2xl text-gray-500">
                        {" "}
                        {data.subscriptions}
                      </p>
                    </div>
                    <button
                      type="button"
                      style={{ backgroundColor: "var(--primary)" }}
                      className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
                    >
                      <FaSchool />
                    </button>
                  </div>
                  <p className="text-gray-500">Total Subscribers</p>
                </div>

                <div className="flex m-3 flex-wrap justify-center space-x-4 items-center">
                  <div className="bg-white h-44 w-full md:w-52  p-8 md:p-4  pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "#03C9D7",
                        backgroundColor: "#E5FAFB",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                    >
                      <BiUser />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {data.clients}
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">All Clients/Partners</p>
                  </div>

                  <div className="bg-white h-44 w-full md:w-52  p-8 md:p-4  pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "rgb(0, 194, 146)",
                        backgroundColor: "rgb(235, 250, 242)",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                    >
                      <BiUser />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {data.units}
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">All Units</p>
                  </div>

                  <div className="bg-white h-44 w-full md:w-52  p-8 md:p-4 pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "rgb(255, 244, 229)",
                        backgroundColor: "rgb(254, 201, 15)",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                    >
                      <AiOutlineUsergroupAdd />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {data.jobs}
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">
                      All Job Vacancies
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Analytics;
