import DateFormater from "../helpers/DateFormater";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const CategroryPublicationsCard = ({ slug,image,categoryId, title, author, createdAt, id }) => {
  return (
    <>
      <div className="col-12 col-md-6 col-lg-4 s-publication-overview__card">
        <div className="bg-white border-[0.5] border-gray-50 rounded-lg shadow-primary shadow-sm overflow-hidden">
          <div className="c-image s-publication-overview__card-image w-full">
            <img
              className="attachment-s-publication-overview__card size-s-publication-overview__card wp-post-image c-image__img"
              alt={title}
              src={image}
              sizes="430px, 430px"
            />
          </div>

          <header className="c-header s-publication-overview__card-header px-3">
            <h3 className="c-heading c-header__heading  s-publication-overview__card-heading">
              {title}
            </h3>
          </header>
          <div className="flex justify-between items-center px-3  mb-3">
            <p className="font-normal text-[15px] text-gray-600">
              <strong className="text-[15px]">By: </strong>
              {author}
            </p>
            <Tag color="orange">
              {" "}
              <DateFormater value={createdAt} />
            </Tag>
          </div>

          <div className="px-3 -mt-4 mb-2 ">
            <button className="s-page-header__buttons w-full mb-2">
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className="c-button c-button--link-with-icon s-page-header__button-1 flex items-center"
                to={`/publications/${slug}/${categoryId}/summary/${id}`}>
                <span className="c-button__label">Read More</span>
                <div className="c-image c-button__icon ">
                  <MdKeyboardArrowRight size={20} />
                </div>
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategroryPublicationsCard;
