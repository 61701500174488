import { useEffect } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getBlogs } from "../states/features/blogs/blogsSlice";

import BlogCard from "../components/BlogCard";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";
import { Empty } from "antd";

const RecentBlogs = () => {
  // dispatch
  const dispatch = useDispatch();

  // staff
  const { blogs, isFetchingAll, message } = useSelector((state) => state.blogs);

  // useEffect
  useEffect(() => {
    dispatch(getBlogs());
  }, [message, dispatch]);

  const recentBlogs = blogs?.data && blogs?.data?.slice(0, 3);

  return (
    <section
      id="section-number-5"
      className="section-number-5 s-featured-related bg-[#d7d4d417]"
    >
      <div className="container s-featured-related__inner">
        <header className="c-header s-featured-related__header text-center">
          <h2 className="c-heading c-header__heading">Recent Blogs</h2>
        </header>

        {isFetchingAll ? (
          <Spinner moreStyles="min-h-[30vh]" />
        ) : (
          <>
            {recentBlogs && recentBlogs.length === 0 ? (
              <div className="my-[10vh] flex items-center justify-center w-full">
                <Empty description="No recent blogs yet" />
              </div>
            ) : (
              <div className="row s-featured-related__posts">
                {recentBlogs &&
                  recentBlogs.map((blog) => (
                    <BlogCard blog={blog} key={blog.id} />
                  ))}
              </div>
            )}
          </>
        )}

        <div className=" s-featured-related__button text-center -mt-20">
          {recentBlogs && recentBlogs.length !== 0 && (
            <Link
              onClick={() => window.scrollTo(0, 0)}
              className="c-button c-button--link-with-icon col-12 d-block s-featured-related__button-link items-center"
              to="/blogs"
              data-wpel-link="internal"
            >
              <span className="c-button__label text-[18px] font-bold">
                More Blogs{" "}
              </span>

              <MdKeyboardArrowRight
                size={40}
                className="c-image c-button__icon "
              />
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default RecentBlogs;
