import React from "react";

const ConfirmModal = ({ cancelButton, okButton, text, icon }) => {
  return (
    <div className="h-screen bg-black/60 z-50 flex flex-col items-start text-gray-900 absolute top-0 right-0 left-0">
      <div className="w-fit mt-[20vh] rounded border-[2px] border-whiteDark bg-white mx-auto overflow-hidden z-10">
        <div className="p-8">
          <div className="flex flex-row items-center gap-2">
            {icon}
            <span className="text-gray-800 font-[500]">{text}</span>
          </div>
          <div className="flex justify-end space-x-2 mt-6">
            {cancelButton}
            {okButton}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
