import API from "../../../api/api";
import { clientEndpoint } from "../../../constants";
import { notification } from "../../../utils";

// Get all clients
const getClients = async () => {
  const response = await API.get(clientEndpoint);
  return response.data;
};

// Add a Client
const addClient = async (data) => {
  const response = await API.post(clientEndpoint, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// Update a Client
const updateClient = async (data) => {
  const response = await API.put(clientEndpoint, data);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// Add a Client
const deleteClient = async (id) => {
  const response = await API.delete(`${clientEndpoint}/${id}`);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

const clientsServices = {
  getClients,
  addClient,
  updateClient,
  deleteClient,
};

export default clientsServices;
