import API from "../../../api/api";
import { blogEndpoint } from "../../../constants";
import { notification } from "../../../utils";

// Get all Blogs
const getBlogs = async () => {
  const response = await API.get(blogEndpoint);
  return response.data;
};

// Get single Blog
const getBlog = async (id) => {
  const response = await API.get(`${blogEndpoint}/${id}`);
  return response.data;
};

// Add a Blog
const addBlog = async (data) => {
  const response = await API.post(blogEndpoint, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// Update a Blog
const updateBlog = async (data) => {
  const response = await API.put(blogEndpoint, data);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

// Delete a Blog
const deleteBlog = async (data) => {
  const response = await API.delete(blogEndpoint, data);
  if (response?.data?.ok) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

const blogServices = {
  getBlogs,
  getBlog,
  addBlog,
  updateBlog,
  deleteBlog,
};

export default blogServices;
